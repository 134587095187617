const allblog=[
    {
      "category": "Data Annotation",
      "title": "The Importance of High-Quality Data Annotation for AI Models",
      "small_desc": "Optimal data annotation vital for accurate AI models.",
      "long_desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu urna sit amet nisi hendrerit placerat.",
      "publish_date": "2023-07-01",
      "author_name": "John Doe",
      "poster":"Blog/blog-1.jpg",
      "long_desc2": " ",
      "url":"/blogs-1"
    },
    {
      "category": "Data Modelling",
      "title": "Dolor Sit Amet 2",
      "small_desc": "Exploring jbk the depths of Dolor Sit Amet 2",
      "long_desc": "Nullam eu urna sit amet nisi hendrerit placerat. Curabitur sit amet orci metus.",
      "publish_date": "2023-07-02",
      "author_name": "Jane Smith",
      "poster":"data-model.avif",
      "long_desc2": " ",
      "url":"/blogs-2"
    },
    {
      "category": "Data Modelling",
      "title": "Adipiscing Elit 3",
      "small_desc": "ihiho into Adipiscing Elit 3",
      "long_desc": "Sed mattis tincidunt pulvinar. Mauris maximus, lorem id tempor viverra, nisi lectus feugiat urna, a rhoncus turpis eros nec tellus.",
      "publish_date": "2023-07-03",
      "author_name": "Mark Johnson",
      "poster":"data-anno.avif",
      "long_desc2": " ",
      "url":"/blogs-3"
    },
    {
      "category": "Data Analysis",
      "title": "Consectetur 4",
      "small_desc": "Unveilinjbg Consectetur 4",
      "long_desc": "Aliquam pulvinar risus nec magna mollis, nec suscipit metus dictum. Etiam vestibulum pulvinar luctus.",
      "publish_date": "2023-07-04",
      "author_name": "Emily Davis",
      "poster":"data-label.avif",
      "long_desc2": " ",
      "url":"/blogs-4"
    },
    {
      "category": "Data Annotation",
      "title": "Mauris Maximus 5",
      "small_desc": "Discovjkering Mauris Maximus 5",
      "long_desc": "Donec pulvinar tincidunt massa, ac tincidujijljnt nunc venenatis a. Nulla facilisi.",
      "publish_date": "2023-07-05",
      "author_name": "Michael Brown",
      "poster":"data-model.avif",
      "long_desc2": " ",
      "url":"/blogs-5"
    },
    {
      "category": "Data Annotation",
      "title": "Nisi Lectus Feugiat 6",
      "small_desc": "Exploring Nisi Lectus Feugiat 6pi",
      "long_desc": "Mauris tincidunt ligula ut malesuada scelerisque.",
      "publish_date": "2023-07-06",
      "author_name": "Sophia Wilson",
      "poster":"data-anno.avif",
      "long_desc2": " ",
      "url":"/blogs-6"
    },
    {
      "category": "Data Annotation",
      "title": "A Rhoncus Turpis 7",
      "small_desc": "Uncovering A Rhoncus Turpis 7",
      "long_desc": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam eu urna sit amet nisi hendrerit placeratb.",
      "publish_date": "2023-07-07",
      "author_name": "Daniel Thompson",
      "poster":"data-model.avif",
      "long_desc2": " ",
      "url":"/blogs-7"
    },
    {
      "category": "Data Annotation",
      "title": "Nec Tellus 8",
      "small_desc": "Revealing Nec Tellus 8",
      "long_desc": "Nullam eu urna sit amet nisi hendrerit placerat. Curabitur sit amet orci metush.",
      "publish_date": "2023-07-08",
      "author_name": "Olivia Anderson",
      "poster":"data-anno.avif",
      "long_desc2": " ",
      "url":"/blogs-8"
    },
    {
      "category": "Data Analysis",
      "title": "Etiam Vestibulum 9",
      "small_desc": "Insights into Etiam Vestibulum 9",
      "long_desc": "Sed mattis tincidunt pulvinar. Mauris maximus, lorem id tempor viverra, nisi lectus feugiat urna, a rhoncus turpis eros nec tellus.",
      "publish_date": "2023-07-09",
      "author_name": "William Garcia",
      "poster":"data-anno.avif",
      "long_desc2": " ",
      "url":"/blogs-9"
    },
    {
      "category": "Data Analysis",
      "title": "Aliquam Pulvinar 10",
      "small_desc": "Understanding Lorem ipsum dolor sit, amet consectetur adipisicing elit. Iusto, nisi",
      "long_desc": "Aliquam pulvinar risus nec magna mollis, nec susbkcipit metus dictum. Etiam vestibulum pulvinar luctus.",
      "publish_date": "2023-07-10",
      "author_name": "Emma Martinez",
      "poster":"data-label.avif",
      "long_desc2": "templorem ipsum dolor sit, magna mollis et sapien",
      "poster-2":"data-model.avif",
      "url":"/blogs-10"
    }
  ]
  

  module.exports=allblog;