import React from 'react'
import "./e.css"
const ERor = () => {
  return (
    <div className='whole_errer'>
		<div className="temp"></div>
      <div className="page">
	<div className="page__info">
		<h1 className="page__title">
			<span className="page__mistake-code">404</span>
			<span className="page__mistake-text">PAGE NOT FOUND !!</span>
		</h1>
		<div className="page__mistake-instructions">
			<p>THIS PAGE IS NOT CURRENLY AVAILABLE .</p>
		</div>
	</div>
	<div className="page__flask page__first-flask flask">
		<div className="flask__shape">
			<div className="flask__throat flask__throat-one"></div>
			<div className="flask__throat flask__throat-two"></div>
			<div className="flask__bottom flask__bottom-one"></div>
			<div className="flask__bottom flask__bottom-two"></div>
			<div className="flask__bottom flask__bottom-three"></div>
		</div>
		<div className="page__bubble-type1 bubble"></div>
		<div className="page__bubble-type2 bubble"></div>
		<div className="page__bubble-type3 bubble"></div>
		<div className="page__bubble-type4 bubble"></div>
		<div className="page__bubble-type5 bubble"></div>
	</div>
	<div className="page__flask page__second-flask flask">
		<div className="flask__shape">
			<div className="flask__throat flask__throat-one"></div>
			<div className="flask__throat flask__throat-two"></div>

			<div className="flask__bottom flask__bottom-one"></div>
			<div className="flask__bottom flask__bottom-two"></div>
			<div className="flask__bottom flask__bottom-three"></div>
		</div>
		<div className="page__bubble-type1 bubble"></div>
		<div className="page__bubble-type2 bubble"></div>
		<div className="page__bubble-type3 bubble"></div>
		<div className="page__bubble-type4 bubble"></div>
		<div className="page__bubble-type5 bubble"></div>
		<div className="page__bubble-type6 bubble"></div>
		<div className="page__bubble-type9 bubble"></div>
	</div>
	<div className="page__flask page__third-flask flask">
		<div className="flask__shape">
			<div className="flask__throat flask__throat-one"></div>
			<div className="flask__throat flask__throat-two"></div>

			<div className="flask__bottom flask__bottom-one"></div>
			<div className="flask__bottom flask__bottom-two"></div>
			<div className="flask__bottom flask__bottom-three"></div>
		</div>
		<div className="page__bubble-type1 bubble"></div>
		<div className="page__bubble-type2 bubble"></div>
		<div className="page__bubble-type3 bubble"></div>
		<div className="page__bubble-type4 bubble"></div>
		<div className="page__bubble-type5 bubble"></div>
		<div className="page__bubble-type6 bubble"></div>
		<div className="page__bubble-type7 bubble"></div>
		<div className="page__bubble-type8 bubble"></div>
	</div>
</div>
    </div>
  )
}

export default ERor
