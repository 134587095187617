import React from "react";
import "./argi.css";
// import x from "../../../Assets/audio_trans.jpg";


import anno from "../../../Assets/Industr_img/ecomm_anno.jpg"
import trans from "../../../Assets/Industr_img/ecco_trans.jpg"
import sour from "../../../Assets/Industr_img/ecomm_sour.jpg"
const ECommercePage = () => {
  return (
    <>
      <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={anno} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
              Data annotation plays a pivotal role in product categorization and
              object detection by enriching images with meaningful information.
              In product categorization, annotators label images with relevant
              product categories, facilitating precise classification and
              streamlined online shopping experiences. For object detection,
              bounding boxes are meticulously drawn around objects of interest,
              enabling algorithms to localize and identify objects accurately.
            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={sour} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Data collection for training recommendation algorithms involves sourcing a diverse and representative set of product images. This process encompasses gathering product images from various online platforms, e-commerce websites, and catalog databases. The images should cover a wide range of products, including different categories, brands, and styles. Ensuring high-quality images is crucial to avoid biases and inaccuracies in the algorithm's recommendations. 
            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={trans} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing customer feedback from audio reviews is a valuable process in gathering and analyzing user sentiments and opinions. It involves converting spoken words from audio recordings into written text, enabling businesses to understand customer experiences and preferences better. By transcribing these reviews, companies can identify recurring themes, assess customer satisfaction levels, and uncover areas for improvement. Transcriptions aid in sentiment analysis and data-driven decision-making, helping businesses enhance their products or services to meet customer needs effectively.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ECommercePage;
