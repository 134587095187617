import React, { useState } from "react";
// import { Link } from 'react-router-dom';
import "./Homepage.css";
// import logo from "../../Assets/logo192.png";
// import x from "../../Assets/Contact_uss.jpg"
import Cards from "../../Components/Cards/Cards";
// import Effect from "../../Components/Anime/Animation";
import {motion} from "framer-motion"
import Bene from "../../Components/Benefits/Bene";
import { useNavigate } from "react-router-dom";
import Scrolltop from "../../Components/Scrolltop";
const Homepage = () => {
  const navigate=useNavigate();
  const handleLinkClick = () => {
    const cardsSection = document.getElementById("services");
    cardsSection.scrollIntoView({ behavior: "smooth" });
  };
  // const speak=()=>{
  //   const x=new SpeechSynthesisUtterance('Redirecting Please Wait');
  //   window.speechSynthesis.speak(x);
  // }
  const [activeTab, setActiveTab] = useState("hire");



  const divVariants = {
    initial: {
      y: -100, // Initial position above the screen
      opacity: 0, // Initially hidden
    },
    animate: {
      y: 0, // Position at the top of the screen
      opacity: 1, // Visible
      transition: {
        type: 'spring', // Spring animation
        stiffness: 150, // Control the stiffness of the spring
        damping: 10, // Control the damping effect of the spring
      },
    },
    rebound: {
      y: 20, // Rebound slightly up after reaching the top
      transition: {
        type: 'spring',
        stiffness: 50,
        damping: 30,
      },
    },
  };

  const handleRebound = () => {
    // Trigger the rebound animation
    // This function will be called when the div reaches the top
    return divVariants.rebound;
  };
  return (
    <>
    {/* <Scrolltop/> */}
      <div className="temp"></div>
<div className="whole_hhh">
      <div className="main_divv">
        {/* homepage */}
        <motion.div
      initial="initial"
      animate="animate"
      variants={divVariants}
      onAnimationComplete={handleRebound}
    >
        <div class="outer_text_home">
          <h1 class="linear">Unblock the X Factor in Your AI Development </h1>
          {/* <Effect /> */}
          <p className="light centerone">Data Excellence Unleashed: Expert Sourcing and Labelling Solutions for Unmatched Insights</p>
          <button className="demo" onClick={()=>navigate('/company')}>Explore us <i
                            className="fa-sharp fa-solid fa-arrow-right fa-bounce fa-xl"
                            style={{ color: "white","font-size":20}}
                          /></button>
        </div>
        </motion.div>
        {/* <button onClick={()=>{
  speak();
}}>speak</button> */}
        {/* <Offer/> */}
        {/* <Effect/> */}
        {/* <div className="amin">
        <div className="homeOuter">
            <div className="homelogo">
              <div className="wholelogo">
                <motion.img src={logo} id="imagebulb" alt="logo" width="250vw" 
                initial={{x:20,opacity:0}}
                animate={{x:0,opacity:1}}
                transition={{delay:1,duration:1}}
                />
                <motion.img  src={logoLine} id="imageline"alt="logoLine" height="500vh" 
                initial={{opacity:0}} 
                animate={{opacity:1}}
                transition={{delay:0.5,duration:0.5}}
                />
                 </div> 
            </div>
        
            <motion.div className="homeFullForm"
            initial={{x:500,opacity:0}}
            animate={{x:0,opacity:1}}
            transition={{delay:1.5,duration:1.5}}
             >
                <h1>LabelX AI</h1>
                <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cupiditate, sapiente. Hic consequuntur, doloribus saepe sunt ad optio necessitatibus quisquam fugit id deserunt! Molestias, omnis reiciendis Lorem, ipsum dolor sit amet consectetur adipisicing elit. Animi, ipsum.</p>
            </motion.div>
        </div>
    </div> */}
      </div>
     <div>
        <section id="hero">
          <h2>Empowering Businesses with Artificial Intelligence</h2>
          <p>Unlock the power of AI to revolutionize your business.</p>
          <button className="demo" onClick={handleLinkClick}>
            Discover Our Services
          </button>
        </section>
      </div>
      <div id="services">
        <Cards />
      </div>
      <div className="whole_loop">
        <div className="container"><h1>Leverage Humans in the Loop</h1></div>
        {/* <h2 className="loop_head">Leverage Humans in the Loop</h2> */}
        <p className="loop_para">
          Say goodbye to spreadsheets, data leaks and quality control issues. We
          allow you to automate your workflows while ensuring the right task is
          assigned to the right person.
        </p>
        <div className="loop_tabs">
          <button
            className={`loop_btn ${activeTab === "hire" ? "current" : ""}`}
            onClick={() => setActiveTab("hire")}
          >
            Human Expertise
          </button>
          <button
            className={`loop_btn ${activeTab === "fully" ? "current" : ""}`}
            onClick={() => setActiveTab("fully")}
          >
            Quality Assurance
          </button>
          <button
            className={`loop_btn ${activeTab === "domain" ? "current" : ""}`}
            onClick={() => setActiveTab("domain")}
          >
            Flexibility & Adaptability
          </button>
          <button
            className={`loop_btn ${activeTab === "security" ? "current" : ""}`}
            onClick={() => setActiveTab("security")}
          >
            Continuous Improvement
          </button>
          <button
            className={`loop_btn ${activeTab === "e" ? "current" : ""}`}
            onClick={() => setActiveTab("e")}
          >
            Social Impact
          </button>
        </div>
        {activeTab === "hire" && <div className="hire">
        {/* <div><img src={x} alt="hd"></img></div> */}
         <div><p>Our team of skilled annotators brings unparalleled human expertise to every data annotation task. With years of experience and a deep understanding of AI development, they ensure precise, contextually relevant, and high-quality data annotations. Leveraging their domain knowledge, our experts can identify subtle patterns and nuances in the data that automated tools might miss. By combining human intelligence with cutting-edge AI technologies, we provide the most accurate and reliable annotations, giving your AI models the edge they need to outperform the competition.</p></div>
            </div>} 
        {activeTab === "fully" && <div className="hire">
        <div><p>With the "Human in the Loop" approach, we guarantee top-tier data validation and quality assurance. Our human reviewers meticulously cross-verify and validate the annotations to eliminate errors and inconsistencies. This iterative refinement process not only fine-tunes the data but also ensures that your AI models receive the most reliable and accurate training data, leading to more robust and effective AI solutions. Our commitment to quality assurance is the backbone of our services, making us a trusted partner for all your AI development needs.</p></div>
            </div>}
        {activeTab === "domain" && <div className="hire">
            <div><p>Our skilled annotators possess the adaptability to handle complex and evolving scenarios. From handling multi-modal data, such as images, videos, and audio, to accommodating custom annotation requirements, our "Human in the Loop" method allows us to adapt quickly and cater to your unique AI project needs. Whether you require keypoint annotation, bounding boxes, semantic segmentation, or any other annotation method, our experts can seamlessly integrate into your AI development workflow. We offer the flexibility you need to achieve AI excellence, no matter how challenging your project may be.</p></div>
            </div>}
        {activeTab === "security" && <div className="hire">
        <div><p>At LabelX AI, we believe that the path to success lies in constant growth and improvement. With our unwavering commitment to excellence, we embrace a culture of continuous learning and refinement. Through rigorous feedback mechanisms and data-driven insights, we consistently enhance our data annotation processes to deliver unparalleled accuracy and efficiency. Our expert team stays ahead of the curve by adopting the latest advancements in AI technology and methodologies, ensuring that your projects are always at the cutting edge of innovation. Experience the power of continuous improvement as we work tirelessly to elevate the quality of your AI development journey, unlocking endless possibilities for your business.</p></div>
            </div>}
            {activeTab === "e" && <div className="e">
        <div><p>At LabelX AI, we are driven by a dual mission: to provide top-tier data annotations for AI development and to create a positive social impact. Through our innovative "Human in the Loop" approach, we empower skilled annotators from rural areas in India, enabling job opportunities and sustainable growth in these communities. This dedication to social impact not only brings cost-effectiveness to our clients but also promotes inclusivity and equality in AI development. Join us in this transformative journey where AI excellence meets purposeful progress, as we drive meaningful change in society while achieving groundbreaking AI success.</p></div>
            </div>}
      </div>
      <Bene title="Why Choose Us?" />
      </div>
    </>
  );
};

export default Homepage;
