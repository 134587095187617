import React from "react";
import "./Cards.css";
import labelling from "../../Assets/data-label.avif";
import sourcing from "../../Assets/data_sourcing.jpg";
import transcription from "../../Assets/transcription.jpg";
// import { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
const Cards = () => {
  // const [showtext, setShowText] = useState(false);
  const navigate = useNavigate();
  // const showText = () => {
  //   setShowText(!showtext);
  // };

  const handleLinkClickcheck = (className) => {
    navigate(`/Services#${className}`);
  };
  return (
    <>
      <div className="gapi"></div>
      <div class="all_cards ">
        <div class="card-category-2">
          {/* <span class="category-name">Our Services</span> <br /> */}
          <div className="container"><h1>Our Services</h1></div>
          <br />
          <ul>
            <li>
              <div class="img-card iCard-style1">
                <div class="card-content">
                  <div class="card-image">
                    <img src={labelling} alt="temp" />
                  </div>

                  <div class="card-text">
                    {/* <span class="card-title">Data labelling</span> */}
                    <p>
                      <h3>Data Labelling </h3>
                      In the data's realm, labels wield great might, Unraveling
                      meaning, illuminating the night. A service that
                      transforms, in clarity and sight.
                    </p>
                  </div>
                </div>

                <div class="card-link">
                <a
                    href="Services#data-aannoo"
                    onClick={() => handleLinkClickcheck("data-aannoo")}
                  >
                    <span className="read_full">Read More  <i
                            className="fa-sharp fa-solid fa-arrow-right fa-bounce fa-xl"
                            style={{ color: "#2869bd","font-size":27}}
                          /></span>
                  </a>
                </div>
              </div>
            </li>
            <li>
              <div class="img-card iCard-style1">
                <div class="card-content">
                  <div class="card-image">
                    {/* <span class="card-title">Data Sourcing</span> */}
                    <img src={sourcing} alt="temp" />
                  </div>

                  <div class="card-text">
                    <p>
                      <h3>Data Sourcing</h3>
                      In the vast data landscape, sources reside, Gathering the
                      threads, where insights hide. A quest for knowledge,
                      through sources we stride.
                    </p>
                  </div>
                </div>

                <div class="card-link">
                <a
                    href="Services#data-sourcing"
                    onClick={() => handleLinkClickcheck("data-sourcing")}
                  >
                  {/* <Link to="/Services#data-aannoo" title="Read Full"> */}
                    <span className="read_full">Read More  <i
                            className="fa-sharp fa-solid fa-arrow-right fa-bounce fa-xl"
                            style={{ color: "#2869bd","font-size":27 }}
                          /></span>
                  {/* </Link> */}

                  </a>
                </div>
              </div>
            </li>
            <li>
              <div class="img-card iCard-style1">
                <div class="card-content">
                  <div class="card-image">
                    {/* <span class="card-title">Transcription</span> */}
                    <img src={transcription} alt="temp" />
                  </div>

                  <div class="card-text">
                    <p>
                      <h3>Transcription</h3>
                      Words spoken, a symphony to transcribe, Capturing voices,
                      as they describe, Transforming speech, from sound to
                      scribe.
                    </p>
                  </div>
                </div>
                <div class="card-link">
                <a
                    href="Services#transcription"
                    onClick={() => handleLinkClickcheck("transcription")}
                  >
                    <span className="read_full">Read More  <i
                            className="fa-sharp fa-solid fa-arrow-right fa-bounce fa-xl"
                            style={{ color: "#2869bd","font-size":27 }}
                          /></span>
                  </a>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <br />
      </div>
    </>
  );
};

export default Cards;
