const knowCommittee = [
    {
        "id" : 1,
        icon : require('../Assets/christian-buehner-DItYlc26zVI-unsplash.jpg'),
        "title" : "Hamza",
        "content" : "Founder"

    },
    {
        "id" : 2,
        icon : require('../Assets/clarisse-meyer-x9cXI2eQLBw-unsplash.jpg'),
        "title" : "Jagjeet",
        "content" : "Vice Chairperson"

    },
    {
        "id" : 3,
        icon : require('../Assets/freestocks-9UVmlIb0wJU-unsplash.jpg'),
        "title" : "Somya",
        "content" : "Secretary"

    },
    {
        "id" : 4,
        icon : require('../Assets/logan-weaver-lgnwvr-p0B7ueoZz8E-unsplash.jpg'),
        "title" : "Suraj",
        "content" : "Joint Sec. / Co-founder"

    }
    
]

export default knowCommittee;