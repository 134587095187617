import React from 'react'
// import x from "../../Assets/audio_anno.jpg"
import  anno from "../../Assets/Industr_img/media_anno.jpg"
import sour from "../../Assets/Industr_img/media_sour.avif"
import trans from "../../Assets/Industr_img/media_trans.avif"
const Media = () => {
  return (
    <div>
       <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={anno} alt="temp" style={{scale:'0.7'}}/>
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Annotating content is crucial for powering content recommendation algorithms in the media and entertainment industry. This involves tagging and categorizing media, such as movies, TV shows, music, articles, and other forms of content, with relevant metadata. Machine learning algorithms leverage these annotations to understand user preferences and behavior, providing personalized content recommendations. Effective data annotation enhances user engagement, content discovery, and overall user satisfaction, leading to increased viewership and loyalty.
            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={sour} alt="temp" style={{scale:'0.7'}}/>
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Collecting user engagement data is essential for content performance analysis in media and entertainment. This process involves gathering data on user interactions, such as views, likes, shares, comments, and watch times. Analyzing this data helps content creators and distributors understand audience preferences, measure content success, and identify areas for improvement. Data-driven insights enable media companies to create more appealing and relevant content, optimize content distribution strategies, and enhance audience engagement.

            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={trans} alt="temp" style={{scale:'0.7'}} />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing interviews and podcasts is a valuable step in content creation and accessibility. This involves converting spoken conversations into written text, enabling media professionals to repurpose the content for various purposes, such as articles, subtitles, and search engine optimization. Transcriptions also make content accessible to individuals with hearing impairments and enable better content comprehension for non-native speakers. Additionally, transcribing interviews and podcasts aids in content indexing, making it easier to search and reference specific information within the media files.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Media
