import React, { useState,useRef } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "./Freesamp.css";
import emailjs from "@emailjs/browser";
import contact from "../../Assets/Contact_uss.jpg"
const SampleRequestForm = () => {
  const formsample=useRef();
  const [formData, setFormData] = useState({
    Name: "",
    company: "",
    email: "",
    phone: "",
    companyWebsite: "",
    sampleType: "",
    sampleDescription: "",
    dataFormat: "",
    quantity: "",
    additionalComments: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // const isValidPhoneNumber = (phoneNumber) => {
  //   const phoneNumberPattern = /^\d{10}$/;
  //   return phoneNumberPattern.test(phoneNumber);
  // };

  const handleSubmit = async (e) => {
    // const { name, value } = e.target;
    // let updatedValue = value;
   
    e.preventDefault(); 
    let value = formData.email;
    console.log(value);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setFormData({ ...formData, email: "" });
      toast.error("Please enter a valid email address");
      return;
    }
    const phoneNumberPattern = /^\d{10}$/;
    if (!phoneNumberPattern.test(formData.phone)) {
      setFormData({ ...formData, phone: "" });
      toast.error("Please enter a valid phone number",{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-custom-style",
      });
      return;
    }
    toast.warning("Processing Your Request, Please wait ", {
      position: "top-left",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      // theme:"dark",
      className: "toast-custom-style",
    });
    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    e.preventDefault();
    try {
      const response = await axios.post("https://website-xi-henna-33.vercel.app/api/v1/forms/freesample", formData, config);
      if (response.status === 201) {
        // toast.success("Sample request submitted successfully!", {
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   // closeOnClick: true,
        //   pauseOnHover: true,
        //   draggable: true,
        //   progress: undefined,
        //   className: "toast-custom-style",
        // });
        setFormData({
          Name: "",
          company: "",
          email: "",
          phone: "",
          companyWebsite: "",
          sampleType: "",
          sampleDescription: "",
          dataFormat: "",
          quantity: "",
          additionalComments: "",
        });
        await emailjs
        .sendForm(
          "service_ec1wk89",
          "template_kp9tbx6",
          formsample.current,
          "xnv5o6rex44ffEwWK"         
        )
        .then(
          (result) => {
            toast.success(
              "Thank you, Your Details has been Submitted will get back to you!",
              {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: "toast-custom-style",
              }
            );
          },
          (error) => {
            console.log(error.text);
            toast.error("Sorry!!! , Some Error Occurred, Please Try Again", {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              // theme:"dark",
              className: "toast-custom-style",
            });
          }
        );
      } else {
        toast.error("Failed to submit sample request.Please try again",{
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          // closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "toast-custom-style",
        });
      }
    } catch (error) {
      toast.error("Failed to submit sample request.Please fill all feilds ",{
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-custom-style",
      });
    }
    // }
  };

  return (
    <>
      <div className="temp"></div>
      <div className="whole_contactus">
        <div className="left_contactus">
          {/* <div className="rrr">
            <img src={contact} alt="contact_us"></img>
          </div> */}
          <div className="animations_c">
            {/* <Lottie animationData={animationData} style={{ height: 320 }} key={3} /> */}
            <img src={contact} alt="contact us" style={{height:400,mixBlendMode:"darken"}}></img>
          </div>
          <div className="title-lockup">
            <h2 className="heading-md">Get in touch</h2>
            <div className="v2-subtitle-sample">
              Request a Free Sample We are pleased to offer a free sample to
              showcase the quality and capabilities of our data annotation
              services. Please fill out the form below to request your
              personalized sample:
            </div>
            <div className="whole_optional_sample">
              <h3>Why Request a Free Sample?</h3>
              <ul>
                <li>
                  Experience Our Expertise: Get a hands-on experience of our
                  data annotation services and witness the accuracy and
                  precision of our annotations.
                </li>
                <li>
                  Evaluate Our Quality: Assess the quality of our annotations
                  and understand how they can enhance the performance of your
                  machine learning models.
                </li>
                <li>
                  Tailored to Your Needs: Receive a customized sample based on
                  your specific requirements, allowing you to see how our
                  services align with your project goals.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="right_contactus">
          <div className="form-containerss">
            <h2>Get Free Data Samples</h2>
            <div className="form-box-sample">
              <form onSubmit={handleSubmit} className="freesamplee" ref={formsample}>
                <label htmlFor="fullName">Full Name:</label>
                <input
                  type="text"
                  id="Name"
                  name="Name"
                  // placeholder="Enter Your Name"
                  value={formData.Name}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="company">Company:</label>
                <input
                  type="text"
                  id="company"
                  name="company"
                  // placeholder="Enter Your Company Name"
                  value={formData.company}
                  onChange={handleChange}
                  required ={true}
                />

                <label htmlFor="email">Email:</label>
                <input
                  type="email"
                  id="email"
                  className="sample_email"
                  name="email"
                  // placeholder="Enter Your Email"
                  value={formData.email}
                  onChange={handleChange}
                  required={true}
                />

                <label htmlFor="phone">Phone:</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  // placeholder="Enter Your Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required={true}
                />

                <label htmlFor="companyWebsite">Company Website:</label>
                <input
                  type="text"
                  id="companyWebsite"
                  name="companyWebsite"
                  // placeholder="Enter Company's website URL if available."
                  value={formData.companyWebsite}
                  onChange={handleChange}
                />

                <label htmlFor="sampleType">Sample Type:</label>
                <select
                  id="sampleType"
                  name="sampleType"
                  value={formData.sampleType}
                  onChange={handleChange}
                  required={true}
                >
                  <option value="">Select Sample Type</option>
                  <option value="Data labelling">Data labelling</option>
                  <option value="Data sourcing">Data sourcing</option>
                  <option value="Transcription">Transcription</option>
                </select>

                <label htmlFor="sampleDescription">Sample Description:</label>
                <textarea
                  id="sampleDescription"
                  name="sampleDescription"
                  value={formData.sampleDescription}
                  onChange={handleChange}
                  required={true}
                />

                <label htmlFor="dataFormat">Data Format:</label>
                <input
                  type="text"
                  id="dataFormat"
                  name="dataFormat"
                  // placeholder="which format? example .py"
                  value={formData.dataFormat}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="quantity">Quantity:</label>
                <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  value={formData.quantity}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="additionalComments">Additional Comments:</label>
                <textarea
                  id="additionalComments"
                  name="additionalComments"
                  value={formData.additionalComments}
                  onChange={handleChange}
                />
                <div className="btn_bookx">
                  <button type="submit" className="hahha">
                    Submit
                  </button>
                </div>
                <ToastContainer />
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="whole_down_workss">
      <div className="work_sample">
              <h3>How It Works</h3>
              <ul>
                <li>
                  Fill out the Sample Request Form: Provide us with some details
                  about your project, including the type of data, annotation
                  requirements, and any specific instructions.
                </li>
                <li>
                  Submission Review: Our team will review your sample request
                  and get in touch with you if any clarifications are needed.
                </li>
                <li>
                  Sample Preparation: Once we have all the necessary
                  information, our experts will prepare a personalized sample
                  for you.
                </li>
                Delivery and Evaluation: We will send you the sample along with
                instructions on how to access and evaluate it.
                <li>
                  Discussion and Next Steps: After reviewing the sample, our
                  team will be available for a discussion to answer any
                  questions, discuss your project requirements, and explore how
                  we can support your data annotation needs.
                </li>
              </ul>
            </div>
            <div className="note_sample">
              <h3>Note</h3>
              <ul>
                <li>The free sample is provided to give you an understanding of our capabilities and the quality of our work. It is not intended for production or commercial use.</li>
                <li>The sample size and complexity may vary based on your specific project requirements.</li>
                <li>Our team will maintain the confidentiality and security of any data provided for the sample.</li>
              </ul>
            </div>
            <div className="ancher">
              <p>If you have any questions or require further assistance, please don't hesitate to contact our team at </p>
              <a href="mailto:support@labelxai.com">support@labelxai.com</a>
            </div>
            </div>
    </>
  );
};

export default SampleRequestForm;
