import React from "react";
import "./Form.css";
// import Lottie from "lottie-react";
// import rating from "../../Assets/customer_review.json";
const Reviews = () => {

  const reviews = [
    {
      id: 0,
      name: "Jagjeet singh",
      job: "UX DESIGNER",
      img: require("../../Assets/cody-scott-milewski-vXgV5XUlTog-unsplash.jpg"),
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus eveniet nemo, at quibusdam facere laboriosam error a animi voluptatem placeat?",
    },
    {
      id: 1,
      name: "ARmaan singh",
      job: "web developer",
      img: require("../../Assets/cody-scott-milewski-Thns35j71L0-unsplash.jpg"),
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus eveniet nemo, at quibusdam facere laboriosam error a animi voluptatem placeat?",
    },
    {
      id: 2,
      name: "Ashwin jadhav",
      job: "web designer",
      img: require("../../Assets/freestocks-9UVmlIb0wJU-unsplash.jpg"),
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus eveniet nemo, at quibusdam facere laboriosam error a animi voluptatem placeat?",
    },
    {
      id: 3,
      name: "peter jones",
      job: "intern",
      img: require("../../Assets/cody-scott-milewski-vXgV5XUlTog-unsplash.jpg"),
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus eveniet nemo, at quibusdam facere laboriosam error a animi voluptatem placeat?",
    },
    {
      id: 4,
      name: "john Doe",
      job: "the boss",
      img: require("../../Assets/logan-weaver-lgnwvr-p0B7ueoZz8E-unsplash.jpg"),
      text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Minus eveniet nemo, at quibusdam facere laboriosam error a animi voluptatem placeat?",
    },
  ];

  return (
    <main id="our_review_section">
      <section className="container_review">
        <div className="title">
          <h2>our reviews</h2>
        </div>
        {/* <div className="content_review">
          <div className="left">
            <Lottie animationData={rating}></Lottie>
          </div>
          </div> */}
        <div className="right">
          <article className="review">
            <div className="img-container">
              <img src={reviews[0].img} alt="person" id="person-img" />
            </div>
            <h4 id="author">{reviews[0].name}</h4>
            <p id="job">{reviews[0].job}</p>
            <p id="info">{reviews[0].text}</p>
            {/* Prev and Next buttons */}
            {/* <div className="button-container">
            <button className="prev-btn" onClick={prevPerson}>
            <i className="fas fa-chevron-left prev_btn_actual"></i>
            </button>
            <button className="next-btn" onClick={nextPerson}>
            <i className="fas fa-chevron-right"></i>
            </button>
        </div> */}
          </article>
          <article className="review">
            <div className="img-container">
              <img src={reviews[1].img} alt="person" id="person-img" />
            </div>
            <h4 id="author">{reviews[1].name}</h4>
            <p id="job">{reviews[1].job}</p>
            <p id="info">{reviews[1].text}</p>
            {/* Prev and Next buttons */}
            {/* <div className="button-container">
            <button className="prev-btn" onClick={prevPerson}>
            <i className="fas fa-chevron-left prev_btn_actual"></i>
            </button>
            <button className="next-btn" onClick={nextPerson}>
            <i className="fas fa-chevron-right"></i>
            </button>
        </div> */}
            {/* <div className="quote">
              <i className="fa-solid fa-quote-right" id="quote"></i>
            </div> */}
            <div className="underline"></div>
          </article>
          <article className="review">
            <div className="img-container">
              <img src={reviews[2].img} alt="person" id="person-img" />
            </div>
            <h4 id="author">{reviews[2].name}</h4>
            <p id="job">{reviews[2].job}</p>
            <p id="info">{reviews[2].text}</p>
            {/* Prev and Next buttons */}
            {/* <div className="button-container">
            <button className="prev-btn" onClick={prevPerson}>
            <i className="fas fa-chevron-left prev_btn_actual"></i>
            </button>
            <button className="next-btn" onClick={nextPerson}>
            <i className="fas fa-chevron-right"></i>
            </button>
        </div> */}         
          </article>
        </div>
       
      </section>
    </main>
  );
};

export default Reviews;
