import React from 'react'
// import x from "../../Assets/audio_anno.jpg"


import anno from "../../Assets/Industr_img/tourism_anno.avif"
import sour from "../../Assets/Industr_img/tourism_sour.avif"
import trans from "../../Assets/Industr_img/tourism_trnas.avif"
const Travel = () => {
  return (
    <div>
       <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={anno} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Annotating tourist location images is essential for developing tourism AI applications. This involves labeling images with relevant information, such as tourist attractions, landmarks, and geographic features. Machine learning algorithms leverage these annotations to assist travelers in identifying and exploring destinations effectively. Proper data annotation enhances the accuracy of AI-driven travel platforms, providing personalized recommendations and enriching tourists' experiences, ultimately promoting tourism growth.
            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={sour} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Gathering traveler reviews plays a crucial role in sentiment analysis for the travel and tourism industry. This process involves collecting feedback and opinions from tourists about their experiences at various destinations, accommodations, and activities. Analyzing this data helps tourism businesses understand customer satisfaction, identify areas for improvement, and tailor offerings to meet travelers' needs. Ethical data collection practices ensure privacy while contributing to the enhancement of travel services and overall tourist satisfaction.
            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={trans} alt="temp" style={{scale:"0.7"}} />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing tour guide audio is significant for improving accessibility in travel and tourism. This involves converting spoken tour narratives into written text, allowing individuals with hearing impairments or language barriers to experience and enjoy guided tours. Transcriptions also create valuable study materials for tourists to review and recall the information provided during their travels. Enhanced accessibility through transcriptions fosters inclusivity and ensures that all tourists can fully participate in and appreciate their travel experiences.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Travel
