import React from 'react'
import x from "../../Assets/audio_anno.jpg"
import anno from "../../Assets/Industr_img/edu_anno.avif"
import sour from "../../Assets/Industr_img/edu_sour.avif"
const Education = () => {
  return (
    <>
     <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={anno} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Annotating educational content is essential for creating interactive learning experiences. This involves tagging educational materials with metadata, such as topics, difficulty levels, and learning objectives. Machine learning algorithms leverage these annotations to provide personalized and adaptive learning paths for students, enhancing engagement and knowledge retention. Proper data annotation enhances the effectiveness of educational technology platforms and enables educators to deliver tailored content that caters to individual learning styles and preferences.
            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={sour} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Collecting student performance data is crucial for educational research and improvement. By gathering data on student assessments, grades, attendance, and learning behaviors, researchers can analyze learning patterns, identify areas of improvement, and develop evidence-based teaching strategies. Ethical data collection practices protect student privacy while contributing to the development of effective educational interventions and policies, ultimately fostering better learning outcomes and educational experiences.
            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={x} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing lectures is essential for promoting accessibility and creating study materials in education. This involves converting audio or video lectures into written text, enabling students to review course content at their own pace and in different formats. Transcriptions support students with disabilities, language barriers, or different learning preferences, making education more inclusive. Additionally, transcribed lectures facilitate the creation of study guides and review materials, aiding students in better understanding and retaining the course material. Transcriptions play a pivotal role in enhancing learning flexibility and supporting students' academic success.
            </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Education
