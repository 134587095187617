import React from "react";
import "./pricing.css";
import Lottie from "lottie-react";
import pricingdata from "../../Assets/pricing.json";
import { useRef } from "react";
import Bene from "../../Components/Benefits/Bene";
import { Link, useNavigate } from "react-router-dom";
const Pricing = () => {
  const navigate = useNavigate();
  const pricinanimation = useRef();
  return (
    <div className="wholewhole">
      <div className="temp"></div>
      <div className="hp yesthisone" id="targetprice">
        <h1>Pricing</h1>
      </div>
      <div className="choose_section">
        <div className="left_choose">
          <Lottie
            lottieRef={pricinanimation}
            animationData={pricingdata}
          ></Lottie>
        </div>

        <div className="right_choose">
          <h3>Choose A plan which Defines You...</h3>
          <p>
            At LabelX AI, we understand that high-quality data is the foundation
            of successful AI projects. Whether you're training cutting-edge
            machine learning models or fine-tuning existing algorithms, having
            accurate and diverse datasets is crucial. That's why we are
            committed to providing top-notch data services that empower your AI
            initiatives.
          </p>
          <br></br>
          <p>
            Our pricing is designed to be transparent and competitive, allowing
            you to access the data services you need without compromising on
            quality. We believe that cost-effectiveness shouldn't come at the
            expense of accuracy and reliability. With LabelX AI, you can be
            assured that you're getting the best value for your investment At
            LabelX AI, our mission is to empower your AI projects with the data
            they deserve. Whether you need image annotation, video
            transcription, or data collection services, our comprehensive
            offerings are designed to meet your needs and exceed your
            expectations.
          </p>
          <br></br>
          <p>
            Partner with LabelX AI today to unlock the full potential of your AI
            algorithms and drive innovation in your industry. Get in touch with
            our team to explore personalized pricing and discover how we can
            support your data-driven success.
          </p>
        </div>
      </div>
      <div className="wholeup">
        <section className="pricing-plans">
          <div className="pricing-card basic">
            <div className="heading">
              <h4>Data <br/> Annotation</h4>
              <p>for small websites or blogs</p>
            </div>
            <p className="start_from">All Starts from</p>
            <p className="price">
              $0.2
              <sub>/entity</sub>
            </p>
            <ul className="features">
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Image</strong> Starting from $0.10 per image
              </li>
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Text</strong> Starting from $0.05 per word/document
              </li>
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Video </strong>Starting from $0.20 per video frame
              </li>
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Annotation support</strong> available
              </li>
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>24/7</strong> support
              </li>
            </ul>
            <button className="cta-btn" onClick={() => navigate("/contactus")}>
              Talk to AI Expert
            </button>
          </div>
          <div className="pricing-card standard">
            <div className="heading">
              <h4>Data<br/>Collection</h4>
              <p>for medium-sized businesses</p>
            </div>
            <p className="start_from">Starts from</p>
            <p className="price">
              $40
              <sub>/dataset</sub>
            </p>
            <ul className="features">
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Image Data </strong>Starting from $50 per dataset
              </li>
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Video Data </strong>Starting from $100 per dataset
              </li>
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Audio Data </strong>Starting from $40 per dataset
              </li>
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Sourcing support</strong> available
              </li>
             <li>
                <i className="fa-solid fa-check"></i>
                <strong>24/7</strong> support
              </li>
            </ul>
            <button className="cta-btn" onClick={() => navigate("/contactus")}>
              Talk to AI Expert
            </button>
          </div>
          <div className="pricing-card premium">
            <div className="heading">
              <h4>Transcription</h4>
              <p>for small businesses</p>
            </div>
            <p className="start_from">Starts from</p>
            <p className="price">
              $0.6
              <sub>/minute</sub>
            </p>
            <ul className="features">
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Audio </strong>Starting from $0.60 per minute
              </li>
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Video </strong>Starting from $0.70 per minute
              </li>
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Multilingual </strong>Starting from $1.00 per minute
              </li>
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>24/7 priority</strong> support
              </li>
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Advanced</strong> features
              </li>
              <li>
                <i className="fa-solid fa-check"></i>
                <strong>Transcription support</strong> available
              </li>
            </ul>
            <button className="cta-btn" onClick={() => navigate("/contactus")}>
              Talk to AI Expert
            </button>
          </div>
        </section>
      </div>
      {/* <div className="wholedown">
        <div className="offer-head">Special Offers</div>

        <div className="container_offer">
          <div className="grid_offer">
            <div className="card_offer card1_offer">
              <h3>BASIC</h3>
              <h2 className="background_font">Free</h2>
              <h4>Free</h4>
              <hr />
              <p>10 templates</p>
              <p>Default presets</p>
              
              <Link to="/freesample">Get Now</Link>
            </div>
          
            <div className="card_offer card2_offer">
              <h3>PRO</h3>
              <h2 className="background_font">100</h2>
              <h4>$100 for year</h4>
              <hr />
              <p>50 templates</p>
              <p>Pro presets</p>
           
              <Link to="/freesample">Get Now</Link>
            </div>
           
            <div className="card_offer card3_offer">
              <h3>ULTIMATE</h3>
              <h2 className="background_font">250</h2>
              <h4>$250 for Year</h4>
              <hr />
              <p>Unlimited templates</p>
              <p>Ultimate presets</p>
              
              <Link to="/freesample">Get now</Link>
            </div>
          </div>
        </div>
      </div> */}
      <Bene title="Benefits of Choosing Us" />
    </div>
  );
};

export default Pricing;
