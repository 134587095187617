import React from 'react'
// import x from "../../Assets/audio_anno.jpg"

import anno from "../../Assets/Industr_img/manu_anno.jpg"
import sour from "../../Assets/Industr_img/manu_sour.jpg"
import trans from "../../Assets/Industr_img/manu_trans.jpg"
const Industry = () => {
  return (
    <div>
       <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={anno} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Annotating quality control images is critical for defect detection in manufacturing. This involves labeling images with information about defects, anomalies, or quality attributes to train machine learning models. By leveraging these annotations, manufacturers can automate the inspection process, identify defects more efficiently, and ensure product quality and consistency, ultimately reducing production costs and minimizing product recalls.

            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={sour} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Collecting production data plays a key role in process optimization for manufacturing. This involves gathering data on various aspects of the production process, such as machine performance, production rates, downtime, and material usage. Analyzing this data allows manufacturers to identify bottlenecks, optimize workflows, and improve overall production efficiency. It also enables data-driven decision-making, leading to higher productivity and resource utilization.

            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={trans} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing assembly line audio is crucial for quality analysis in manufacturing. This process involves converting audio recordings from the assembly line into written text, allowing manufacturers to analyze worker interactions, equipment sounds, and process noises. Transcriptions help detect potential issues, understand root causes of defects, and improve worker training and safety measures. Additionally, audio transcription enables the documentation of valuable insights and best practices, promoting knowledge sharing and continuous improvement in the manufacturing process.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Industry
