// import logo from './logo.svg';
import "./App.css";
import { Route, Routes } from "react-router-dom";
import Homepage from "./Pages/Homepage/Homepage";
import Contactus from "./Pages/Contact_us/Contactus";
// import Error from "./Pages/Error/Error";
// import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import { useState, useEffect } from "react";
import Splash from "./Components/Preloader/Splash";
import About from "./Pages/About_us/About";
// import Cards from "./Components/Cards/Cards";
import Faq from "./Pages/Faq/faq";
// import pricing from "./Pages/Pricing/pricing";
import Pricing from "./Pages/Pricing/pricing";
import Meganavbar from "./Components/Mega_navbar/Navigation";
import Teamcard from "./Components/Team_cards/Teamcard";
// import Testimonials from "./Components/offer_cards/Offer";
import Form from "./Components/ReviewsForm/Form";
import Service from "./Pages/Services/Service";
import ERor from "./Components/Errror/ERor";
import Questionall from "./Pages/Table_question/Question";
import Bookchat from "./Pages/Table_book_a_chat/Bookchat";
import Tabularcontact from "./Pages/Table_contact/Tabularcontact";
import Home from "./Pages/SPECIAL_Access/Home";
import UpwardsArrow from "./Components/Arrow/Arrow";
import Bene from "./Components/Benefits/Bene";
import Industry from "./Pages/Industry/Industry";
import Blog from "./Pages/Blog/Blog";
// import BlogPost from "./Pages/Blogpost/Blogpost";
import SampleRequestForm from "./Pages/Freesample/Freesample";
import Sampleall from "./Pages/Free_samp/Showsamp";
import Workflow from "./Pages/Workflow/Workflow";
import SecurityPage from "./Pages/Security/Security";
import Impdataanno from "./Components/blogposts/Importance_of_data_anno";
import Scrolltop from "./Components/Scrolltop";
// import Agriculture from "./Components/Agriculture/Agriculture";
import Automotive from "./Components/Industry/Automotive/Automotive"
import ECommercePage from "./Components/Industry/ARGI/Argi";
import Health from "./Components/Industry/Healtcare/Healtcare";
import Retail from "./Components/Industry/Retail/Retail";
import Agriculture from "./Components/Industry/Agriculture";
import Education from "./Components/Industry/Education";
import Energy from "./Components/Industry/Energy";
import Finance from "./Components/Industry/Finance";
import Logistics from "./Components/Industry/Logistics";
import Media from "./Components/Industry/Media";
import Realestate from "./Components/Industry/Realestate";
import Sports from "./Components/Industry/Sports";
import Travel from "./Components/Industry/Travel";
import Ind from "./Components/Industry/Industry"
import Blog2 from "./Components/blogposts/Blog2/Blog2";
import ComputerVision from "./Components/blogposts/Blog3";
import Joinus from "./Components/Joinus/Join_us";
import News from "./Pages/Newsletter/News";
import TermsAndConditions from "./Components/Terms/Terms";
// import Sitemap from "./Pages/Sitemap.js";
// import Automotive from "./Components/Industry/Automotive/Automotive";
function App() {
  const [loading, setLoading] = useState(false);
  // const [isauth, setisAuth] = useState(false);
  useEffect(() => {
    // const userinfo = localStorage.getItem("userinfo");
    // setisAuth(userinfo !== null);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);
  return loading ? (
    <Splash />
  ) : (
    <>
      <Scrolltop />
      <Meganavbar />
      <UpwardsArrow />
      <Routes>
        <Route path="/" element={<Homepage />}></Route>
        <Route path="/contact_us" element={<Contactus />}></Route>
        <Route path="/company" element={<About />}></Route>
        <Route path="/faq" element={<Faq />}></Route>
        <Route path="/pricing" element={<Pricing />}></Route>
        <Route path="/team" element={<Teamcard />}></Route>
        <Route path="/contactus" element={<Contactus />}></Route>
        <Route path="/Services" element={<Service />}></Route>
        <Route path="/workflow" element={<Workflow />}></Route>
        <Route path="/security" element={<SecurityPage />}></Route>
        <Route path="/blog-1" element={<Impdataanno />}></Route>
        <Route path="/blog-2" element={<Blog2/>}></Route>
        <Route path="/blog-3" element={<ComputerVision/>}></Route>
        <Route path="/industry" element={<Industry />}></Route>
        <Route path="/reviews" element={<Form />}></Route>
        <Route path="/Benefits" element={<Bene />}></Route>
        <Route path="/freesample" element={<SampleRequestForm />}></Route>
        <Route path="/blog" element={<Blog />} />
        {/* <Route path="/blogs/:title" element={<BlogPost />} /> */}
        <Route path="/newsletter" element={<News/>}></Route>
        <Route path="/terms" element={<TermsAndConditions/>}></Route>
        <Route path="/industry/ecommerce" element={<ECommercePage />}></Route>
        <Route path="/industry/health" element={<Health />}></Route>
        <Route path="/industry/automotive" element={<Automotive/>}></Route>
        <Route path="/industry/retail" element={<Retail/>}></Route>
        <Route path="/industry/agriculture" element={<Agriculture/>}></Route>
        <Route path="/industry/education" element={<Education/>}></Route>
        <Route path="/industry/energy" element={<Energy/>}></Route>
        <Route path="/industry/finance" element={<Finance/>}></Route>
        <Route path="/industry/manufacturer" element={<Ind/>}></Route>
        <Route path="/industry/logistics" element={<Logistics/>}></Route>
        <Route path="/industry/media" element={<Media/>}></Route>
        <Route path="/industry/realestate" element={<Realestate/>}></Route>
        <Route path="/industry/sports" element={<Sports/>}></Route>
        <Route path="/industry/travel" element={<Travel/>}></Route>
        <Route path="/join_us" element={<Joinus/>}></Route>

        <Route
          path="/allquestion"
          element={<Questionall/>}
        ></Route>
        <Route path="/allchat" element={<Bookchat />}></Route>
        <Route path="/allcontact" element={<Tabularcontact />}></Route>
        <Route path="/allsample" element={<Sampleall />}></Route>
        <Route path="/specialaccess/login" element={<Home />}></Route>
        {/* <Route path="/sitemap.xml" element={<Sitemap/>}/> */}
        <Route path="*" element={<ERor/>}></Route>
      </Routes>
      <Footer />
    </>
  );
}

export default App;
