import { useNavigate } from "react-router-dom";
import React from "react";
import "./nav.css";
const Nav = () => {
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("userinfo");
    navigate("/");
  };
  const allcontact = () => {
    navigate("/allcontact");
  };
  const allchat = () => {
    navigate("/allchat");
  };
  const allquestion = () => {
    navigate("/allquestion");
  };
  const allsample = () => {
    navigate("/allsample");
  };

  const newsletter = () => {
    navigate("/newsletter");
  };
  return (
    <>
      <div className="special_nav">
        <button className="custom-button" onClick={allquestion}>
          All Question
        </button>
        <button className="custom-button" onClick={newsletter}>
          Newsletter
        </button>
        <button className="custom-button" onClick={allcontact}>
          All Contact
        </button>
        <button className="custom-button" onClick={allchat}>
          All Book Chat
        </button>
        <button className="custom-button" onClick={allsample}>
          All Sample
        </button>
        <button className="custom-button" onClick={logout}>
          Logout
        </button>
      </div>
    </>
  );
};

export default Nav;
