import React from 'react'
// import x from "../../Assets/audio_anno.jpg"

import anno from "../../Assets/Industr_img/enery_anno.jpg"
import sour from "../../Assets/Industr_img/energy_sour.jpg"
import trans from "../../Assets/Industr_img/energy_trans.jpg"
const Energy = () => {
  return (
    <div>
       <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={anno} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Annotating sensor data is critical for predictive maintenance in energy grids. This involves labeling sensor readings from power lines, transformers, and other grid components with information about their condition, performance, and potential anomalies. Machine learning algorithms leverage these annotations to predict equipment failures, schedule maintenance proactively, and minimize downtime. Proper data annotation enhances the reliability and efficiency of energy grids, ensuring uninterrupted power supply and reducing maintenance costs.

            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={sour} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Collecting energy consumption data is essential for effective energy management. This process involves gathering information on energy usage from residential, commercial, and industrial sectors. Analyzing this data helps utilities and consumers identify patterns, peak demand periods, and opportunities for energy conservation. Accurate and real-time energy consumption data supports load balancing, demand response programs, and efficient energy distribution, contributing to sustainable energy practices and cost optimization.

            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={trans} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing safety briefings is crucial for ensuring the well-being of utility workers in the energy and utilities sector. This involves converting spoken safety instructions, protocols, and guidelines into written text. Transcriptions help utility workers understand and follow safety procedures accurately, reducing the risk of accidents and injuries in hazardous work environments. Accessible safety briefings also promote compliance with safety regulations and foster a safety-oriented work culture, ultimately safeguarding the health and safety of utility personnel.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Energy
