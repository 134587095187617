import React from 'react'
// import x from "../../Assets/audio_anno.jpg"

import anno from "../../Assets/Industr_img/fit_anno.jpg"
import sour from "../../Assets/Industr_img/fir_sour.jpg"
import trans from "../../Assets/Industr_img/fit_trans.jpg"
const Sports= () => {
  return (
    <div>
       <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={anno} alt="temp" style={{scale:"0.8"}}/>
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Annotating sports event videos is essential for in-depth analysis and insights. This process involves labeling video frames with relevant information, such as player positions, game events, and performance metrics. Machine learning algorithms leverage these annotations to track player movements, identify patterns, and evaluate strategies. Proper data annotation enhances sports performance analysis, providing coaches and athletes with valuable information to improve training, tactics, and overall performance.

            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={sour} alt="temp" style={{scale:"0.8"}}/>
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Collecting fitness tracking data is crucial for personalized coaching and training in sports and fitness. This involves gathering data from wearable devices and fitness apps, such as heart rate, steps taken, calories burned, and exercise routines. Analyzing this data allows coaches and fitness trainers to tailor workout programs, monitor progress, and set achievable goals for individuals. Ethical data collection practices ensure user consent and privacy, providing a foundation for effective fitness coaching and personalized training experiences.

            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={trans} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing sports commentary is valuable for detailed analysis and understanding of sporting events. This involves converting spoken commentary from live broadcasts into written text. Transcriptions help sports analysts, journalists, and fans review key moments, game strategies, and player performances. Accessible and accurate transcriptions enable comprehensive post-match analysis, aid in player evaluations, and provide deeper insights into the dynamics of the game. Additionally, transcribed commentary enriches media coverage and fan engagement, enhancing the overall sports experience.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Sports
