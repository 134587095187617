import React from 'react'
import x from "../../Assets/audio_anno.jpg"
const Logistics = () => {
  return (
    <div>
       <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={x} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Annotating product images is crucial for inventory management AI in logistics and supply chain. This involves labeling images with product identifiers, SKUs, and quantities. Machine learning algorithms leverage these annotations to accurately track inventory levels, detect stockouts, and forecast demand. Proper data annotation enhances inventory accuracy and efficiency, reducing carrying costs and ensuring smooth operations within the supply chain.

            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={x} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Collecting supply chain performance data is essential for optimization. This involves gathering data on lead times, transportation costs, order fulfillment rates, and inventory turnover. Analyzing this data helps logistics professionals identify inefficiencies, streamline processes, and make data-driven decisions to improve supply chain performance. Effective data collection fosters transparency, visibility, and collaboration among supply chain partners, contributing to overall supply chain effectiveness and customer satisfaction.
            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={x} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing shipment status updates is significant for real-time tracking in logistics and supply chain management. This process involves converting spoken or text-based updates about shipment locations, delivery dates, and delays into written text. Transcriptions enable logistics professionals and customers to track and monitor shipments accurately, reducing uncertainty and improving delivery timelines. Accessible shipment status updates facilitate proactive decision-making, customer communication, and efficient problem-solving, enhancing the reliability and responsiveness of the logistics and supply chain process.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Logistics