import React from 'react'
import "./join.css"
import join from "../../Assets/join_us.jpg"
const Join_us = () => {
  return (
    <div>
        <div className="temp"></div>
      <div className="header_js">
      <h1>Join Us at the Beginning of Something Extraordinary!</h1>
    </div>
    <div className="container_js">
    <div className="image_js">
        <img src={join} alt="Join Our Team" />
      </div>
      <p>LabelX AI is at the forefront of empowering AI companies to unleash their full potential. We specialize in providing the foundation for Powerful AI with our Powerful Data and Powerful Team.</p>
      <p>As pioneers in data annotation and sourcing, we recognize the critical role of high-quality data in driving AI advancements. By collaborating with us, you'll be part of a team that fuels AI innovations and transforms industries.</p>
      <p>If you're passionate about AI and eager to contribute to cutting-edge technology, we invite you to be part of our journey. Send your CV to <a href="mailto:support@labelxai.com" className='mailwe'>support@labelxai.com</a> and step into the world of AI possibilities. Together, let's shape the future of AI-powered solutions!</p>
      <div className="cta-button-wrapper_js">
        <a className="cta-button_js" href="mailto:support@labelxai.com">Send Your CV Now</a>
      </div>
    </div>
    </div>
  )
}

export default Join_us
