import React, { useState, useEffect } from 'react';
import './About.css';
import {motion} from 'framer-motion'
const AboutSection = ({ title, description, image, reverse,idd }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const getImage = async () => {
      try {
        const importedImage = await import(`../../Assets/${image}`);
        setImageUrl(importedImage.default);
      } catch (error) {
        console.error(`Error importing image: ${image}`, error);
      }
    };

    getImage();
  }, [image]);

  return (
    <>
    {reverse ? (
     <motion.div 
      initial={{x:"-100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"-100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}
      >
    <div className={`about-section ${reverse ? 'reverse' : ''}`}>
      <div className="about-section-image">
        {imageUrl && <img src={imageUrl} alt={title} id={idd} />}
      </div>
      <div className="about-section-content">
        <h2 className="about-section-title">{title}</h2>
        <p className="about-section-description">{description}</p>
      </div>
    </div>
    </motion.div>
  ):(
  <>
    <motion.div className='videoText'
      initial={{x:"100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.6,ease:'easeOut'}}
      >
   <div className={`about-section ${reverse ? 'reverse' : ''}`}>
      <div className="about-section-image">
        {imageUrl && <img src={imageUrl} alt={title} id={idd} />}
      </div>
      <div className="about-section-content">
        <h2 className="about-section-title">{title}</h2>
        <p className="about-section-description">{description}</p>
      </div>
    </div>
    </motion.div>
  </>
  )
}
    </>
  );
};

const OurAbout = () => {
  return (
    <div className="our-abouts">
      {/* <h1 className="page-title">Our About</h1> */}

      <AboutSection
        title="About LabelX AI"
        description="LabelX AI is a trusted partner for AI companies seeking superior data labeling and data sourcing services, empowering AI companies to unlock the full potential of their machine learning projects. Our dedicated team of experts is passionate about delivering high-quality annotated datasets and sourcing relevant data to fuel AI innovation. With our deep expertise in AI and a commitment to delivering exceptional results, we enable our clients to accelerate their AI initiatives and achieve transformative outcomes."
        image="logo192.png"
        idd="data-ano"
      />

      <AboutSection
        title="Data Labeling Services"
        description="With our advanced data labeling services, we enable AI companies to train and fine-tune their models with accurate and reliable annotations. Our skilled annotators meticulously label various types of data, including images, videos, and text, using industry-standard annotation techniques and tools. We offer a wide range of annotation services, including object detection, image classification, semantic segmentation, text categorization, and more. By ensuring precise annotations, we help AI algorithms learn and generalize effectively, leading to superior model performance."
        image="data-label.avif"
        reverse={true}
        idd="data-labelling-services"
      />

      <AboutSection
        title="Data Sourcing Services"
        description="At LabelX AI, we understand the critical importance of high-quality training data for AI projects. Our data sourcing services cater to the specific needs of AI companies, providing access to diverse and relevant datasets. Leveraging our extensive network and expertise, we curate, verify, and collect datasets that align with your project requirements. Whether you need image datasets, text corpora, or video collections, our team will source and deliver the data that best suits your AI initiatives."
        image="data_sourcing.jpg"
        idd="data-sourcing"
      />
      
      <AboutSection
        title="Our Approach"
        description="Quality, efficiency, and confidentiality are at the core of our operations. We adhere to rigorous quality control measures to ensure accurate and consistent annotations. Our streamlined workflows and optimized processes enable us to deliver annotated datasets within agreed timelines. We also prioritize data security and confidentiality, implementing strict measures to safeguard your sensitive information throughout the data labeling and sourcing processes."
        image="approch.jpg"
        idd="approachess"
        reverse={true}
      />
      <AboutSection
        title="Collaborative Partnerships"
        description="We believe in building strong and collaborative partnerships with our clients. Our team works closely with you to understand your project requirements, annotation guidelines, and data sourcing preferences. We aim to provide personalized solutions that align with your unique needs, ensuring that our services contribute to the success of your AI initiatives.
        "
        // image="collob.jpg"
        image="cp.jpg"
        idd="collaborative"
      />
      <AboutSection
        title="Unparalleled Expertise"
        description="Our team comprises highly skilled professionals with extensive experience in data annotation, computer vision, natural language processing, and machine learning. With a deep understanding of the intricacies of AI development, we provide expert guidance and support to ensure the highest quality data annotations and sourced datasets.
        "
        image="expp.jpg"
        idd="unparalleled"
        reverse={true}
      />
       <AboutSection
        title="Industry-Specific Knowledge"
        description="At LabelX AI, we recognize that each industry has unique data requirements. Whether you operate in healthcare, autonomous vehicles, e-commerce, or any other sector, our team possesses industry-specific knowledge to cater to your specialized needs. We stay updated with the latest advancements and best practices in data labeling and sourcing within your industry to deliver the most accurate and relevant results.
        "
        image="ind_know.jpg"
        idd="industry-specific"
      />
       <AboutSection
        title="Cutting-Edge Technology"
        description="We leverage advanced annotation tools and technologies to streamline the data labeling process and enhance efficiency. Our proprietary software and customizable workflows allow for seamless collaboration, annotation version control, and quality assurance, ensuring precise and consistent results at scale.
        "
        image="cut_edge.jpg"
        reverse={true}
        idd="cutting-edge"
      />
       <AboutSection
        title="Scalability and Flexibility"
        description="LabelX AI is equipped to handle projects of any size and complexity. Our scalable infrastructure enables us to meet growing demands and deliver results within tight deadlines. We understand the evolving nature of AI projects and offer flexibility to accommodate changes, iterations, and custom requirements, ensuring that our services align with your evolving needs.
        "
        image="scalable.jpg"
        idd="scalability"
      />
       <AboutSection
        title="Customer-Centric Approach"
        description="We prioritize customer satisfaction and aim to build long-term partnerships based on trust, transparency, and collaboration. Our team works closely with you to fully understand your project goals and provide tailored solutions. We value open communication, feedback, and continuous improvement, ensuring that our services align with your expectations and contribute to your success.
        "
        image="cust.jpg"
        reverse={true}
        idd="customer-centric"
      />
       <AboutSection
        title="Our Commitment to Quality"
        description="Quality is at the heart of everything we do. We maintain stringent quality control processes to ensure accurate annotations and reliable sourced data. Our experienced quality assurance team conducts thorough checks to ensure the integrity, consistency, and completeness of the labeled datasets and sourced data, enabling you to have the utmost confidence in the quality of your training data.
        "
        image="commit.jpg"
        idd="quality"
      />
       <AboutSection
        title="Let's Transform AI Together"
        description="Partner with LabelX AI and unlock the true potential of your AI projects. Together, we can harness the power of high-quality data, advanced technology, and industry expertise to drive innovation, solve complex challenges, and create a brighter AI-powered future."
        image="tranf.jpg"
        reverse={true}
        idd="let-s-transform"
      />
       <AboutSection
        title="Contact Us"
        description="If you have any inquiries, collaboration opportunities, or would like to discuss your data labeling or data sourcing needs, we invite you to get in touch with us. Visit our Contact page or email us at support@labelxai.com, and our dedicated team will be delighted to assist you."        
        image="Contact_uss.jpg"
        idd="contact"
      />
    </div>
  );
};

export default OurAbout;
