import React, { useState, useRef, useEffect } from "react";
import "./Contactus.css";
// import Lottie from "lottie-react";
// import contactus from "../../Assets/contactus_message.json";
import Scrolltop from "../../Components/Scrolltop";
import { toast, ToastContainer } from "react-toastify";
import axios from "axios";
import emailjs from "@emailjs/browser";
// import Map from "../../Components/Map/Map";
import Splash from "../../Components/Preloader/Splash";
import contact from "../../Assets/Contact_uss.jpg";
// const contactusani=lazy(()=>import( "../../Assets/contactus_message.json"));
const Contactus = () => {
  // console.log(contactusani);
  const [activeTab, setActiveTab] = useState("send-message");
  const [name, setName] = useState("");
  // const [activechat, setchat] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [company, setCompany] = useState("");
  const [message, setMessage] = useState("");
  const [interestedService, setInterestedService] = useState("");
  const formbook = useRef();
  const secondform = useRef();
  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === "send-message") {
      // setchat(false);
    }
    // else setchat(true);
  };

  const isValidPhoneNumber = (phoneNumber) => {
    const phoneNumberPattern = /^\d{10}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(phone)) {
      toast.warning("Please Enter a Phone number and then Retry", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme:"dark",
        className: "toast-custom-style",
      });
      secondform.current.reset();
      return;
    }
    if (isValidEmail(email)) {
      toast.warning("Processin Your Request....", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-custom-style",
      });

      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const { data } = await axios.post(
          "https://website-xi-henna-33.vercel.app/api/v1/forms/Contactus",
          {
            name: name,
            email: email,
            phoneno: phone,
            companyname: company,
            message: message,
          },
          config
        );
        // console.log(data);
      } catch (error) {
        toast.warning({
          title: "Error Occured!",
          description: error.response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
          className: "toast-custom-style",
        });
        setName("");
        setEmail("");
        setInterestedService("");
        setPhone("");
        setCompany("");
        setMessage("");
        return;
      }
      await emailjs
        .sendForm(
          "service_ec1wk89",
          "template_2vba19g",
          secondform.current,
          "xnv5o6rex44ffEwWK"
        )
        .then(
          (result) => {
            toast.success(
              "Thank you, Your Details has been Submitted will get back to you!",
              {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: "toast-custom-style",
              }
            );
          },
          (error) => {
            console.log(error.text);
            toast.error("Sorry!!! , Some Error Occurred, Please Try Again", {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              className: "toast-custom-style",
            });
          }
        );
    } else {
      toast.warning("Please Enter a Valid Email and then Retry", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme:"dark",
        className: "toast-custom-style",
      });
    }
    secondform.current.reset();
    // alert("Submitted!")
    setName("");
    setEmail("");
    setInterestedService("");
    setPhone("");
    setCompany("");
    setMessage("");
  };

  function isValidEmail(email) {
    const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailFormat.test(email);
  }
  // const handleform=(e)=>{
  // e.preventDefault();
  // toast.warning("Processin Your Request....",{
  //   position: "top-right",
  //   autoClose: 5000,
  //   hideProgressBar: false,
  //   closeOnClick: true,
  //   pauseOnHover: true,
  //   draggable: true,
  //   progress: undefined});
  // }
  const handleform = async (e) => {
    e.preventDefault();
    if (isValidEmail(email)) {
      toast.warning("Processin Your Request....", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        // closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: "toast-custom-style",
      });

      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        // https://labelx-ai.onrender.com/api/v1/forms/Question
        const { data } = await axios.post(
          "https://website-xi-henna-33.vercel.app/api/v1/forms/Bookchat",
          {
            name: name,
            email: email,
            service: interestedService,
          },
          config
        );
        // console.log(data);
      } catch (error) {
        toast.warning({
          title: "Error Occured!",
          description: error.response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom",
          className: "toast-custom-style",
        });
        setName("");
        setEmail("");
        setInterestedService("");
        return;
      }
      await emailjs
        .sendForm(
          "service_ec1wk89",
          "template_2vba19g",
          formbook.current,
          "xnv5o6rex44ffEwWK"
        )
        .then(
          (result) => {
            toast.success(
              "Thank you, Your Details has been Submitted will get back to you!",
              {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: "toast-custom-style",
              }
            );
          },
          (error) => {
            console.log(error.text);
            toast.error("Sorry!!! , Some Error Occurred, Please Try Again", {
              position: "top-left",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              // theme:"dark",
              className: "toast-custom-style",
            });
          }
        );
    } else {
      toast.warning("Please Enter a Valid Email and then Retry", {
        position: "top-left",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme:"dark",
        className: "toast-custom-style",
      });
    }
    formbook.current.reset();
    // alert("Submitted!")
    setName("");
    setEmail("");
    setInterestedService("");
  };

  const [animationData, setAnimationData] = useState(null);
  useEffect(() => {
    import("../../Assets/contactus_message.json")
      .then((data) => {
        setAnimationData(data.default);
      })
      .catch((error) => {
        console.error("Failed to load animation:", error);
      });
  }, []);

  if (!animationData) {
    return <Splash />;
  }

  return (
    <>
      <div className="temp"></div>
      <Scrolltop />
      <div className="whole_contactus">
        <div className="left_contactus">
          <div className="animations_c">
            {/* <Lottie animationData={animationData} style={{ height: 320 }} key={3} /> */}
            <img
              src={contact}
              alt="contact us"
              style={{ height: 400, mixBlendMode: "darken" }}
            ></img>
          </div>
          <div className="title-lockup">
            <h2 className="heading-md">Get in touch</h2>
            <div className="v2-subtitle">
              We’d love to hear about your use cases, feel free to reach out to
              us or schedule a demo for a more in-depth discussion.
            </div>
            <pre className="v2-subtitle mb-1-5">
              Need technical support? Please contact
            </pre>
            <div className="ancher">
              <a href="mailto:support@labelxai.com">support@labelxai.com</a>
            </div>
            
            <p>
              <br/>
               Dubai Office: 203, Street 7, <br />Discovery Gardens,
              Dubai, UAE 122002. <br/>
              <br/>
              Hyderabad Office: 112, Financial
              District, Gachibowli, Hyderabad, India 500032.
            </p>
          </div>
          {/* <h3>Get in Touch</h3>
          <div className="info_contactus">
            <h5>Address: Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis, harum.</h5>
          </div> */}
        </div>
        <div className="right_contactus">
          <div className="form-container">
            <div className="tabs">
              <button
                className={`tab ${
                  activeTab === "send-message" ? "active" : ""
                }`}
                onClick={() => handleTabChange("send-message")}
              >
                Send Message
              </button>
              <button
                className={`tab ${activeTab === "book-chat" ? "active" : ""}`}
                onClick={() => handleTabChange("book-chat")}
              >
                Book a Chat
              </button>
              <ToastContainer />
            </div>

            <div className="form-content">
              {activeTab === "send-message" && (
                <form
                  className="send-message-form"
                  ref={secondform}
                  onSubmit={handleSubmit}
                >
                  <label htmlFor="name">Name*</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    // placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required={true}
                  />

                  <label htmlFor="email">Email*</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    // placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                  />

                  <label htmlFor="phone">Phone Number*</label>
                  <input
                    type="tel"
                    id="phone"
                    // placeholder="Enter your phone number"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required={true}
                  />

                  <label htmlFor="company">Company Name*</label>
                  <input
                    type="text"
                    id="company"
                    // placeholder="Enter your company name"
                    value={company}
                    onChange={(e) => setCompany(e.target.value)}
                    required={true}
                  />

                  <label htmlFor="message">Message*</label>
                  <textarea
                    id="message"
                    // placeholder="How can we help?"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    required={true}
                  ></textarea>
                  <div className="btn_submit_full">
                    <button type="submit">Send</button>
                  </div>
                </form>
              )}

              {activeTab === "book-chat" && (
                <form
                  className="book-chat-form"
                  ref={formbook}
                  onSubmit={handleform}
                >
                  <label htmlFor="name">Name*</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    // placeholder="Enter your name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required={true}
                  />

                  <label htmlFor="email">Email*</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    // placeholder="Enter your email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required={true}
                  />

                  <label htmlFor="interestedService">Interested Service*</label>
                  <select
                    id="interestedService"
                    value={interestedService}
                    onChange={(e) => setInterestedService(e.target.value)}
                    required={true}
                  >
                    <option value="">Select Interested Service</option>
                    <option value="Data Annotation">Data Annotation</option>
                    <option value="Data Sourcing">Data Sourcing</option>
                    <option value="Transcription">Transcription</option>
                  </select>
                  <div className="btn_book">
                    <button type="submit" className="hahha">
                      Book
                    </button>
                    <ToastContainer />
                  </div>
                </form>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <Map /> */}
      {/* <Map/> */}
    </>
  );
};

export default Contactus;
