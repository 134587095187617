import React from 'react'
import "./Offer.css"
const Offer = () => {
  return (
    <>
    <div className="whole_sliding_effect">
      <div className="cardxxxx">
    <h1>Our Servies 
      <div className="scroller">
        <span>
          Data Labelling<br/>
          Image Annotaion<br/>
          Text Annotation<br/>
          Audio Annotation<br/>
          Data Sourcing<br/>
        </span>
      </div>
    </h1>
  </div>
  </div>
    </>
  )
}

export default Offer
