import React from 'react'
// import x from "../../Assets/audio_anno.jpg"
import anno from "../../Assets/Industr_img/finance_anno.jpg"
import trans from "../../Assets/Industr_img/finance_trans.jpg"
import sour from "../../Assets/Industr_img/finance_sour.jpg"
const Finance = () => {
  return (
    <div>
      <div>
       <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={anno} alt="temp" style={{scale:"0.7"}}/>
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Annotating financial documents is crucial for sentiment analysis in finance. By labeling texts such as news articles, social media posts, and analyst reports with sentiment indicators (positive, negative, neutral), machine learning algorithms can assess market sentiment and investor emotions. This aids financial professionals in making informed decisions, predicting market movements, and understanding investor sentiment, ultimately influencing trading strategies and portfolio management.
            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={sour} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Gathering stock market data is essential for predictive analytics in finance. This involves collecting historical and real-time data on stock prices, trading volumes, financial ratios, and market indicators. Analyzing this vast dataset empowers financial analysts and algorithmic traders to develop predictive models, detect patterns, and forecast market trends. Accurate and timely data collection is crucial for making well-informed investment decisions, mitigating risks, and maximizing returns in the dynamic and fast-paced world of finance.

            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={trans} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing earnings calls is a significant part of financial reporting. During these calls, company executives discuss financial results, performance, and future outlook with analysts and investors. Transcribing these conversations ensures accurate and accessible records of the discussions, which are vital for regulatory compliance, investor relations, and financial analysis. Accessible transcriptions help financial analysts and stakeholders gain insights into a company's performance, strategy, and financial health, aiding in investment decision-making and overall market transparency.
            </p>
          </div>
        </div>
      </div>
    </div>
    </div>
  )
}

export default Finance
