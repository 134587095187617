import React from "react";
import "./Workfloe.css";
import Flowchart from "../../Components/Flowchart/Flowchart";
const Workflow = () => {
  return (
    <>
      <div className="temp"></div>
      <Flowchart />
    </>
  );
};

export default Workflow;
