import React from "react";
import "./flow.css";
import { motion } from "framer-motion";
const Flowchart = () => {
  return (
    <>
      <div className="eventContainer">
        <div className="eventTimeLine">
          <div className="upcomingeventsCircle">
            <h2>WORKFLOW</h2>
          </div>

          <ul>
            <li>
            <motion.div 
      initial={{x:"-100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"-100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}
      >
               <div className="motionaldivision">
                <div className="eventTimeLineContent" key={1}>
                  <div className="eventRight">
                    <div>
                      <h1>1. Requirement Analysis</h1>
                    </div>
                    <div>
                      <p>
                      We begin by closely collaborating with you to understand your
              project requirements, annotation guidelines, and dataset
              specifications. Our team works with you to define the scope,
              annotation types, labeling schema, and any specific instructions for
              the data annotations or sourced datasets.
                      </p>
                    </div>
                  </div>
                </div>
                </div>
                </motion.div>
            </li>
            <li>
              {/* <motion.div
                className="motionaldivision"
                initial={{ y: "10%", opacity: 0 }}
                whileInView={{ y: "0px", opacity: 1 }}
                transition={{ duration: 1, delay: 0.3, ease: "easeOut" }}
              > */}
               <motion.div 
      initial={{x:"100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}>
            <div className="motionaldivision">
                <div className="eventTimeLineContent" key={2}>
                  <div className="eventLeft">
                    <h1>2. Data Collection and Preparation</h1>
                    <p>If you opt for our data sourcing services, our team scours various
              sources to identify and collect the most suitable datasets for your
              project. We ensure that the sourced data is relevant, diverse, and
              meets your specific criteria. In case you provide the data for
              annotation, we carefully analyze and prepare it for the annotation
              process.</p>
                  </div>
                </div>
              {/* </motion.div> */}
              </div>
              </motion.div>
            </li>

            <li>
            <motion.div 
      initial={{x:"-100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"-100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}>
            <div className="motionaldivision">
                <div className="eventTimeLineContent" key={3}>
                  <div className="eventRight">
                    <h1>3. Annotation Methodology Selection</h1>
                    <p>
                    Based on your requirements and the nature of your data, we select
              the appropriate annotation methodologies and tools. We leverage a
              combination of manual annotation by our expert annotators and
              automated annotation techniques, where applicable, to maximize
              efficiency and accuracy.
                    </p>
                  </div>
                </div>
              {/* </motion.div> */}
              </div>
              </motion.div>
            </li>
            <li>
            <motion.div 
      initial={{x:"100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}>
            <div className="motionaldivision">
              
                <div className="eventTimeLineContent" key={4}>
                  <div className="eventLeft">
                    <h1>4. Annotation Execution</h1>
                    <p>
                    Our skilled annotators meticulously annotate your data following
              the defined guidelines and labeling schema. They apply their
              expertise and attention to detail to ensure precise and consistent
              annotations. Throughout the annotation process, we maintain open
              communication with you to address any questions or clarifications
              that may arise.
                    </p>
                  </div>
                </div>
              {/* </motion.div> */}
              </div>
              </motion.div>
            </li>
            <li>
            <motion.div 
      initial={{x:"-100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"-100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}>
            <div className="motionaldivision">
              
                <div className="eventTimeLineContent" key={5}>
                  <div className="eventRight">
                    <h1>5. Quality Assurance</h1>
                    <p>
                    Quality assurance is a critical step in our workflow. Our dedicated
              quality assurance team meticulously reviews the annotated data to
              ensure accuracy, consistency, and adherence to your guidelines.
              They conduct thorough checks, perform inter-annotator agreement
              tests, and address any issues or discrepancies found during the
              quality control process.
                    </p>
                  </div>
                 
                </div>
              {/* </motion.div> */}
              </div>
              </motion.div>
            </li>

            <li>
            <motion.div 
      initial={{x:"100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}>
              <div className="motionaldivision">
                <div className="eventTimeLineContent" key={6}>
                  <div className="eventLeft">
                    <h1>6. Iteration and Feedback</h1>
                    <p>
                    We value your feedback and strive to meet your expectations. If any
              revisions or iterations are necessary, we work closely with you to
              make the required adjustments. We maintain open lines of
              communication to ensure that the annotations or sourced datasets
              align with your evolving needs and project goals.
                    </p>
                  </div>
                </div>
              {/* </motion.div> */}
              </div>
              </motion.div>
            </li>
            <li>
            <motion.div 
      initial={{x:"-100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"-100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}>
            <div className="motionaldivision">
              
                <div className="eventTimeLineContent" key={7}>
                  <div className="eventRight">
                    <h1>7. Delivery and Integration</h1>
                    <p>
                    Once the annotations are finalized and quality assured, we deliver
              the annotated data or sourced datasets to you in the agreed-upon
              format. We provide comprehensive documentation to facilitate the
              integration of the data into your AI pipeline or machine learning
              workflows.
                    </p>
                  </div>
                </div>
              {/* </motion.div> */}
              </div>
              </motion.div>
            </li>
            <li>
            <motion.div 
      initial={{x:"100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}>
            <div className="motionaldivision">
              
                <div className="eventTimeLineContent" key={8}>
                  <div className="eventLeft">
                    <h1>8. Ongoing Support</h1>
                  <p>Our commitment doesn't end with the delivery of the annotated data
              or sourced datasets. We offer ongoing support to address any
              questions or concerns that may arise after the delivery. Our team
              is dedicated to ensuring your satisfaction and the successful
              integration of the data into your AI projects.
                    </p>
                  </div>
                </div>
              {/* </motion.div> */}
              </div>
              </motion.div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Flowchart;
