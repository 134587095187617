import React, { useState, useRef } from "react";
import "./Footer.css";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
// import logo from "../../Assets/logo192.png";
import { toast, ToastContainer } from "react-toastify";
import emailjs from "@emailjs/browser";
import validator from "validator";
import axios from "axios";
const Footer = () => {
  const formemail = useRef();
  const moveBy = () => {
    window.scrollTo({ top: 0 });
  };
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isRegistered, setIsRegistered] = useState(false);
  const [isNotRegistered, setIsNotRegistered] = useState(false);
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };
  const handleEmail = async (e) => {
    // toast.warning("Processin Your Request....", {
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   // closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    //   className: "toast-custom-style",
    // });
    try {
      e.preventDefault();
      setIsNotRegistered(false);
      setIsRegistered(false);
      // console.log(email);
      if (validate(email) === 1) {
        try {
          await axios.post(
            "https://website-xi-henna-33.vercel.app/api/v1/forms/newsletter",
            { email },
            config
          );
        } catch (error) {
          console.log(error);
          console(error.response.message);
          toast.warning({
            title: "Error Occured!",
            description: error.response.data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "bottom",
            className: "toast-custom-style",
          });
          setEmail("");
          return;
        }
        await emailjs
          .sendForm(
            "service_j7co5ja",
            "template_dv878hm",
            formemail.current,
            "BztxLy-9XorlierDN"
          )
          .then(
            (result) => {
              toast.success(
                "Thank you, Your Details has been Submitted will get back to you!",
                {
                  position: "top-left",
                  autoClose: 5000,
                  hideProgressBar: false,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  className: "toast-custom-style",
                }
              );
              setIsRegistered("true");
              formemail.current.reset();
            },
            (error) => {
              console.log(error.text);
              toast.error("Sorry!!! , Some Error Occurred, Please Try Again", {
                position: "top-left",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: "toast-custom-style",
              });
            }
          );
      } else {
        setIsNotRegistered("true");
        toast.error("Enter a Valid mail", {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: "toast-custom-style",
        });
      }
      setEmail("");
    } catch (e) {
      alert("Something went wrong!");
      // console.log(e.response.config.message);
      // toast.error({
      //   title: "Error Occured!",
      //   description:"EMAIL ALREADY EXISTS",
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      //   position: "bottom",
      //   className: "toast-custom-style",
      // });
      setEmail("");
      return;
    }
  };
  const validate = (email) => {
    if (validator.isEmail(email)) return 1;
    return -1;
  };
  return (
    <>
      {/* <div className="cover"> */}
      {/* <div className="footerOuter">
        <p
          className="emailRegistered"
          style={{ display: isRegistered ? "block" : "none" }}
        >
          Thank You for Connecting With Us!!!
        </p>
        <p
          className="emailNotRegistered"
          style={{ display: isNotRegistered ? "block" : "none" }}
        >
          Error please try again!
        </p>
        <div className="footerx">
          <div className="footerUpperContainer">
            <div className="whole_outer_footer_text">
              <h2>Explore Limitless Possibilities</h2>
              <p>We are excited to connect with you, are you?</p>
            </div>
            <form className="buttonIn" ref={formemail}>
              <div className="whole_wrapup">
                <input
                  type="email"
                  id="footerInfoEntry"
                  name="UsersEmailId"
                  placeholder="Example@gmail.com"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  value={email}
                />
                <div className="btn_jn">
                  <button
                    type="submit"
                    className="btn_jj"
                    value="Join Now"
                    id="footerJoinNow"
                    onClick={handleEmail}
                  >
                    Let's Connect
                  </button>
                  <ToastContainer />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> */}
      <footer className="footer">
        <div className="containerx">
          <div className="rowx">
            <div className="footer-col ">
              {/* <img src={logo} alt="company_logo"></img> */}
              <h4>Company</h4>
              <ul>
                <li>
                  <Link to="/company">About Us</Link>
                </li>
                <li>
                  <Link to="/Services">Join Us</Link>
                </li>
                <li>
                  <Link to="/workflow">Workflow</Link>
                </li>
                <li>
                  <Link to="/security">Security</Link>
                </li>
                <li>
                  <Link to="/contactus">Contact Us </Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Industries</h4>
              <ul>
                <li>
                  <Link to="/industry/health">Healtcare</Link>
                </li>
                <li>
                  <Link to="/industry/automotive">Automobile</Link>
                </li>
                <li>
                  <Link to="/industry/manufacturer">Industrial</Link>
                </li>
                <li>
                  <Link to="/industry/agriculture">Agriculture</Link>
                </li>
                <li>
                  <Link to="/industry/finance">Finance</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col get_help">
              <h4>Services</h4>
              <ul>
                <li>
                  <Link to="/Services">Data Labelling</Link>
                </li>
                <li>
                  <Link to="/Services">Data Sourcing</Link>
                </li>
                <li>
                  <Link to="/Services">Transcription</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col get_help">
              <h4>Resources</h4>
              <ul>
                <li>
                  <Link to="/blog">Blogs</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="">Use Cases</Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>follow us</h4>
              <div className="social-links">
                <Link to="https://www.linkedin.com/company/labelxai">
                  <i className="fab fa-linkedin-in"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="terms_codn tests">
          <Link to="/terms" onClick={() => moveBy()}>
            <p className="">&copy;LabelX Ai Terms & Conditions</p>
          </Link>
        </div>

        {/* <div className="newsletter">
      <form id="v7-newsletter-footer">
        <div class="footer-2021---form-header">
          Subscribe to our monthly newsletter
        </div>
        <div class="footer-2021---form-flex">
          <input
            type="email"
            class="footer-2021---form-input w-input"
            maxlength="256"
            name="email-3"
            data-name="Email 3"
            placeholder="Enter your email"
            id="email-3"
            required={true}
          />
          <input
            type="submit"
            value="--&gt;"
            data-wait="Please wait..."
            class="footer-2021---submit-button w-button"
          />
        </div>
        <div class="footer-2021---form-text">
          News, product updates, and blog articles on AI
        </div>
      </form>
      </div> */}
      </footer>
    </>
  );
};

export default Footer;
