import React from "react";
import "./healt.css";
import x from "../../../Assets/audio_trans.jpg";

import data_anno from "../../../Assets/Industr_img/medical_sour.jpg"
import data_sour from "../../../Assets/Industr_img/medical_anno.jpg"
import data_trans from "../../../Assets/Industr_img/medical_trans.jpg"
const  Health= () => {
  return (
    <>
      <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={data_anno} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Annotating medical images is a crucial aspect of healthcare AI applications. By labeling medical images with structures, conditions, and anomalies, such as tumors or fractures, machine learning algorithms can assist medical professionals in accurate diagnosis and analysis. This process plays a pivotal role in improving the efficiency and reliability of medical imaging systems, enabling early detection and personalized treatment plans for patients, ultimately contributing to better healthcare outcomes.
            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={data_sour} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Collecting patient data is vital for medical research and analysis. This involves gathering diverse patient information, such as demographics, medical history, test results, and treatment outcomes. Ethical and secure data collection ensures compliance with privacy regulations while providing valuable insights for medical studies, clinical trials, and healthcare policymaking. Robust datasets help researchers identify trends, risk factors, and potential treatments, advancing medical knowledge and improving patient care.

            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={data_trans} alt="temp" style={{scale:"0.7"}}/>
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing medical dictations involves converting spoken physician notes into written records. This process ensures accurate and accessible patient documentation, facilitating seamless communication among healthcare providers. Transcriptions aid in maintaining comprehensive medical histories, treatment plans, and progress notes, contributing to informed decision-making and continuity of care. Furthermore, electronic health record (EHR) integration streamlines healthcare workflows, reducing administrative burden and allowing medical professionals to focus more on patient care while maintaining detailed and up-to-date records.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Health;
