import React, { useState, useEffect } from "react";
import { useTable } from "react-table";
import Nav from "../../Components/SpecialNav/Nav";
import { useNavigate } from "react-router-dom";
function Tabularcontact() {
  const navigate=useNavigate();
  const [question, setquestion] = useState([]);
  const [isauth, setisAuth] = useState(false);
  const userinfo = localStorage.getItem("userinfo");
  const Getcontact = async() => {
    try{
      const userInfoString = localStorage.getItem('userinfo');
      const userInfo = JSON.parse(userInfoString);
      const token = userInfo.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
    await fetch("https://website-xi-henna-33.vercel.app/api/v1/forms/getallcontact",{
      headers:headers
    })
      .then((res) => res.json())
      .then((data) => {
        setquestion(data);
        // console.log(data);
      })
      .catch((err) => {console.error("Error: ", err);navigate('/');});
  }catch(error){
alert("NOt Authorized ");
setisAuth(false);
navigate('/');
  }
};
  useEffect(() => {
    Getcontact();
    setisAuth(userinfo !== null);
    // console.log(isauth);
  }, [isauth, userinfo]);
  const data = question;
  const columns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone no.",
        accessor: "phoneno",
      },
      {
        Header: "Company",
        accessor: "companyname",
      },
      {
        Header: "Message",
        accessor: "message",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });


  return isauth ? (
    <div className="Appx">
      <div className="temp"></div>
      <Nav/>
      
      <div className="container_table">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}> {cell.render("Cell")} </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <>
      <div className="temp"></div> 
      <h1>You Are Not Authorized To this Page</h1>
    </>
  );
}

export default Tabularcontact;
