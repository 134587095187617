import React from 'react'
import "./service.css"
import Offer from '../../Components/sliding_services/Offer';
import Infinite from '../../Components/Infinite_scroll/Infinite';
import OurServices from '../../Components/Service/OurService';
const Service = () => {
  return (
    <>
    <div className="temp"></div>
      <div className="efffect_rot">
      <Offer/>
      </div>
      <div className="whole_ssss">
      <OurServices/>
      </div>
      <Infinite/>
    </>
  )
}

export default Service;
