import React from 'react'
// import x from "../../Assets/audio_anno.jpg"

import anno from "../../Assets/Industr_img/realestate_anno.avif"
import sour from "../../Assets/Industr_img/realestate_sour.avif"
import trans from "../../Assets/Industr_img/realstate_trans.avif"
const  Realestate = () => {
  return (
    <div>
       <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={anno} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Annotating property images is essential for real estate analysis. This process involves labeling images with relevant information, such as property type, features, amenities, and condition. Machine learning algorithms utilize these annotations to categorize properties, predict property values, and match buyers with suitable listings. Proper data annotation enhances the efficiency of real estate platforms, facilitating accurate property searches and informed decision-making for buyers and sellers.

            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={sour} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Collecting property data is crucial for conducting market research in real estate. This involves gathering information on property sales, rental rates, property features, and market trends. Analyzing this data enables real estate professionals to assess property demand, price fluctuations, and investment opportunities. Ethical data collection practices ensure compliance with privacy regulations while providing valuable insights to support informed real estate decisions.

            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={trans} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing property listings simplifies property searches for potential buyers and renters. This involves converting the information in property listings, such as descriptions, specifications, and contact details, into written text. Transcriptions make it easier for users to search, filter, and compare properties, enhancing user experience on real estate platforms. Additionally, transcriptions enable better indexing and search engine optimization (SEO) for property listings, increasing visibility and accessibility for potential clients.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Realestate
