import React from 'react'
// import x from "../../Assets/audio_anno.jpg"

import anno from "../../Assets/Industr_img/agri_data_anno.jpg"
import sour from "../../Assets/Industr_img/agri_sour.jpg"
import trans from "../../Assets/Industr_img/agri_trans.jpg"
const Agriculture = () => {
  return (
    <div>
       <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={anno} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Annotating crop images is vital for precision agriculture AI applications. This process involves labeling images with crop types, growth stages, diseases, and pest infestations. Machine learning algorithms leverage these annotations to monitor crop health, identify potential issues, and optimize resource allocation. Proper data annotation enhances the accuracy and efficiency of precision agriculture systems, allowing farmers to make data-driven decisions and maximize crop yields while minimizing resource usage.
            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={sour} alt="temp" />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Collecting weather and soil data is crucial for effective crop management in agriculture. This involves gathering information on temperature, precipitation, humidity, soil moisture, and nutrient levels. Analyzing this data helps farmers implement appropriate irrigation schedules, fertilizer application, and pest control measures. Real-time weather and soil data enable precision agriculture practices, ensuring sustainable farming, reducing environmental impact, and maximizing crop productivity.

            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={trans} alt="temp" style={{scale:"0.8"}}/>
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing agricultural expert consultations is valuable for knowledge sharing among farmers and agronomists. This process involves converting spoken consultations, workshops, or training sessions into written text. Transcriptions make agricultural expertise more accessible and allow farmers to refer to the insights and recommendations shared by experts. Additionally, transcriptions facilitate the creation of agricultural guides and manuals, enabling effective knowledge transfer and best practices adoption, ultimately supporting the growth and development of the agricultural community.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Agriculture
