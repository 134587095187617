import React from "react";
import "./slide.css";
import { useNavigate } from "react-router-dom";
import imaged from "../../Assets/Blog/blog-1.jpg";
import imaged2 from "../../Assets/Blog/mll.jpg";
import imaged3 from "../../Assets/Blog/cv.jpg";
import ml from "../../Assets/Blog/ml.avif"
import { motion } from "framer-motion";
const Blogslide = () => {
  const navigate = useNavigate();
  return (
    <>
      <motion.div
        initial={{ x: "-100%", opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        exit={{ x: "-100%", opacity: 0, transition: { duration: 0, delay: 0 } }}
        transition={{ duration: 0.5, delay: 0.3, ease: "easeOut" }}
      >
        <div className="whole_slidy">
          <div className="blog-card">
            <input type="radio" name="select" id="tap-1" defaultChecked />
            <input type="radio" name="select" id="tap-2" />
            <input type="radio" name="select" id="tap-3" />
            <input type="checkbox" id="imgTap" />
            <div className="sliders">
              <label htmlFor="tap-1" className="tap tap-1"></label>
              <label htmlFor="tap-2" className="tap tap-2"></label>
              <label htmlFor="tap-3" className="tap tap-3"></label>
            </div>
            <div className="inner-part">
              <input type="radio" name="img" id="img-1" defaultChecked />
              <label htmlFor="imgTap" className="img">
                <img className="img-1" src={imaged2} alt="I1" />
              </label>
              <div className="contentblog contentblog-1">
                <span>5 September 2023</span>
                <div className="titleblog">
                  The Importance of Data Annotation
                </div>
                <div className="text">
                Data annotation is vital for machine learning, as it involves labeling data to train models, enabling accurate predictions and advancing AI technologies.
                </div>
                <button onClick={() => navigate("/blog-1")} className="demo">Read more</button>
              </div>
            </div>
            <div className="inner-part">
              <input type="radio" name="img" id="img-2" />
              <label htmlFor="imgTap" className="img">
                <img className="img-2" src={ml} alt="I2" />
              </label>
              <div className="contentblog contentblog-2">
                <span>19 Augest 2023</span>
                <div className="titleblog">What Is Machine Learning: Unleashing the Power of Artificial Intelligence </div>
                <div className="text">
                Discover the essence of machine learning in AI revolution.Read more to Unveiling the potential and applications of machine learning.
                </div>
                <button onClick={() => navigate("/blog-2")} className="demo">Read more</button>
              </div>
            </div>
            <div className="inner-part">
              <input type="radio" name="img" id="img-3" />
              <label htmlFor="imgTap" className="img">
                <img className="img-3  ix" src={imaged3} alt="I3" />
              </label>
              <div className="contentblog contentblog-3">
                <span>26 Augest 2023</span>
                <div className="titleblog">Let's Talk Computer Vision: When Machines Get Superpowers to See!</div>
                <div className="text">
                Computer vision grants machines superpowers to interpret visual data.
Read More to Exploring the fascinating world of computer vision..
                </div>
                <button onClick={() => navigate("/blog-3")} className="demo">Read more</button>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Blogslide;
