import React ,{useState,useEffect} from "react";
import { useTable } from "react-table";
import Nav from "../../Components/SpecialNav/Nav";

function Bookchat() {
    const [question,setquestion]=useState([]);
    const [isauth, setisAuth] = useState(false);
    const userinfo = localStorage.getItem('userinfo');
    const Getchat = async() => {
      const userInfoString = localStorage.getItem('userinfo');
      const userInfo = JSON.parse(userInfoString);
      const token = userInfo.token;
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      try{
        await fetch('https://website-xi-henna-33.vercel.app/api/v1/forms/getallbookchat',{
          headers:headers
        })
        .then(res => res.json())
        .then(data =>{
          setquestion(data);
          // console.log(data)
        })
          .catch((err) => console.error("Error: ", err));
        }catch(error){
alert(error);
        }
	}
    useEffect(() => {
		Getchat();
    setisAuth(userinfo !== null); 
    // console.log(isauth);
	}, [isauth,userinfo]);
  const data =question;
  const columns = React.useMemo(
    () => [
      {
        Header: "First Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Service",
        accessor: "service",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
      isauth?(
    <div className="Appx">
      <div className="container_table">
      <Nav/>
      <div className="temp"></div>
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}> {cell.render("Cell")} </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
    ):(<>
      <div className="temp"></div>
      <h1>You Are Not Authorized To this Page</h1>
      </>)
  );
}

export default Bookchat;