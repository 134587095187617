import React ,{useState,useEffect} from "react";
import { useTable } from "react-table";
import Nav from "../../Components/SpecialNav/Nav";
import { toast , ToastContainer} from "react-toastify";
import { useNavigate } from "react-router-dom";
function Sampleall(props) {
  const navigate=useNavigate();
    const [question,setquestion]=useState([]);
    const [isauth, setisAuth] = useState(false);
    const userinfo = localStorage.getItem('userinfo');
    const GetQuestions = async() => {
      try{
        const userInfoString = localStorage.getItem('userinfo');
        const userInfo = JSON.parse(userInfoString);
        const token = userInfo.token;
        const headers = {
          Authorization: `Bearer ${token}`,
        };
        
		await fetch('https://website-xi-henna-33.vercel.app/api/v1/forms/getallsample', {
      headers: headers,
    })
			.then(res => res.json())
			.then(data =>{
        setquestion(data);
        console.log(data)
      })
			.catch((err) => {
        toast.error('Sorry!!! , Some Error Occurred, Please Try Again', {
          position: "top-left",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: 'toast-custom-style',
        });
        console.error("Error: ", err)
        setisAuth(false);
        navigate('/');
      });
	}
  catch(error){
    setisAuth(false);
  }
}
    useEffect(() => {
      GetQuestions();
    setisAuth(userinfo !== null); 
    console.log(isauth);
	}, [isauth]);
  const data =question;
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "Name",
      },
      {
        Header: "Company",
        accessor: "company",
      },
      {
        Header: "email",
        accessor: "email",
      },
      {
        Header: "phone",
        accessor: "phone",
      },
      {
        Header: "sample-type",
        accessor: "sampleType",
      },
      {
        Header: "desc",
        accessor: "sampleDescription",
      },
      {
        Header: "Format",
        accessor: "dataFormat",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Comments ",
        accessor: "additionalComments",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    isauth?(
      <>
      <div className="Appx">
        {/* <div className="temp"></div> */}
      <div className="temp"></div>
      
      <Nav/>
      <ToastContainer/>
      <div className="container_table">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}> {cell.render("Cell")} </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
    </>
          ):(
            <>
            <div className="temp"></div>
            <h1>You Are Not Authorized To this Page</h1>
            </>
          )
  );
}

export default Sampleall;