import React from "react";
import "./term.css";
import Scrolltop from "../Scrolltop";

const TermsAndConditions = () => {
  return (
    <>
      <div className="temp"></div>
      <div className="whole_terms">
        <div className="terms-container">
          <section className="terms-section">
            <h2>Cookie Policy</h2>
            <p>Last updated: 15th Aug, 23</p>
            <p className="light">
              This Cookie Policy explains how Labelx AI uses cookies on the
              website www.labelxai.com. By using the Site, you agree to the use
              of cookies as described in this policy.
            </p>
            <h3>How We Use Cookies</h3>
            <p>
              We use cookies to enhance your user experience, remember your
              preferences, and analyze website traffic. Cookies help us provide
              you with a more personalized experience and improve our website's
              content and functionality.
            </p>
            <h3>Types of Cookies We Use</h3>
            <ul>
              <li>
                <strong>Necessary Cookies:</strong> These cookies are essential
                for the proper functioning of our website. They enable basic
                features such as page navigation and access to secure areas of
                the site.
              </li>
              <li>
                <strong>Analytical Cookies:</strong> These cookies help us
                understand how users interact with our website by collecting
                anonymous data. We use this information to improve our website
                and services.
              </li>
              <li>
                <strong>Functional Cookies:</strong> These cookies remember your
                preferences and settings to provide you with a more personalized
                experience.
              </li>
            </ul>
            <h3>Your Cookie Choices</h3>
            <p>
              You can choose to accept or decline cookies through your browser
              settings. Most web browsers automatically accept cookies, but you
              can usually modify your browser settings to decline cookies if you
              prefer. However, please note that some features of the website may
              not function properly without cookies.
            </p>
          </section>

          <section className="terms-section">
            <h2>Privacy Policy</h2>
            <p>Last updated: 15th Aug, 23</p>
            <p className="light">
              This Privacy Policy informs you of our policies regarding the
              collection, use, and disclosure of Personal Information we receive
              from users of the Site.
            </p>
            <h3>Information Collection and Use</h3>
            <p>
              We may ask you to provide us with certain personally identifiable
              information that can be used to contact or identify you.
              Personally identifiable information may include but is not limited
              to your name, email address, postal address, and phone number. We
              collect this information when you voluntarily submit it to us
              through our website's contact form or other communication
              channels.
            </p>
            <h3>Log Data</h3>
            <p>
              Like many site operators, we collect information that your browser
              sends whenever you visit our Site ("Log Data"). This Log Data may
              include information such as your computer's Internet Protocol
              ("IP") address, browser type, browser version, the pages of our
              Site that you visit, the time and date of your visit, the time
              spent on those pages, and other statistics. This information is
              used for analytical purposes to understand how users interact with
              our website and to improve its content and functionality.
            </p>
            <h3>Cookies</h3>
            <p>
              We use cookies to enhance your user experience and track your
              interactions with the Site. Cookies help us provide you with a
              more personalized experience, remember your preferences, and
              analyze website traffic. You can choose to disable or delete
              cookies through your browser settings, but please note that some
              features of the website may not function properly without cookies.
            </p>
            <h3>Data Security</h3>
            <p>
              We take data security seriously and implement appropriate measures
              to protect your personal information. However, please be aware
              that no method of transmission over the Internet or electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your data, we cannot guarantee its
              absolute security.
            </p>
          </section>

          <section className="terms-section">
            <h2>Terms of Service</h2>
            <p>Last updated: 15th Aug, 23</p>
            <p className="light">
              Use of the Site By accessing and using the Site, you agree to
              comply with these Terms. If you do not agree with any part of
              these Terms, please do not use the Site.
            </p>
            <h3>Intellectual Property</h3>
            <p>
              The content, logos, and trademarks displayed on the Site are the
              property of [Your Company Name] or its licensors and are protected
              by intellectual property laws. You may not use, reproduce, modify,
              or distribute any of the content without our prior written
              consent.
            </p>
            <h3>Third-Party Links</h3>
            <p>
              The Site may contain links to third-party websites for your
              convenience. However, we do not endorse or control these
              third-party sites, and we are not responsible for their content or
              privacy practices. Your use of third-party sites is at your own
              risk.
            </p>
            <h3>Disclaimer of Warranties</h3>
            <p>
              The information on the Site is provided "as is" without any
              representations or warranties, express or implied. We make no
              warranties regarding the accuracy, completeness, or reliability of
              the content on the Site.
            </p>
            <h3>Limitation of Liability</h3>
            <p>
              In no event shall [Your Company Name] be liable for any direct,
              indirect, incidental, consequential, or special damages arising
              out of or in any way connected with your use of the Site or
              reliance on its content.
            </p>
            <h3>Changes to These Terms</h3>
            <p>
              We may update these Terms from time to time without notice. It is
              your responsibility to review these Terms periodically for any
              changes. By continuing to use the Site after any modifications to
              these Terms, you agree to be bound by the updated Terms.
            </p>
          </section>

          <div className="contact terms">
            <p className="txtx">
              <span className="hightlight">Contact Us </span>If you have any
              questions about our Cookie Policy, Privacy Policy, or Terms of
              Service, please contact us at&nbsp;
              <a href="mailto:support@labelxai.com">support@labelxai.com</a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsAndConditions;
