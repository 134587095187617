import React from 'react'
import "./bene.css"
const Bene = (props) => {
  return (
    <>
          <div className="container">
      <h1>{props.title}</h1>
      <div className="bene_desc"><p>At LabelX AI, we take pride in being your trusted partner in the world of AI data solutions. When you choose to work with us, you benefit from our commitment to excellence, expertise in data annotation, data collection, transcription services, a client-centric approach. Here are compelling reasons to partner with LabelX AI.</p></div>
      <div className="row_bene">
        <div className="service">
          <i className="fas fa-laptop-code"></i>
          <h2>Unparalleled Expertise</h2>
          <p>
          Our team of experienced data scientists and domain experts possess a deep understanding of the intricacies of data annotation, collection, and transcription.
          </p>
        </div>
        <div className="service">
          <i className="fas fa-chart-line"></i>
          <h2>Accuracy and Precision</h2>
          <p>
          Data accuracy is at the core of our services. Our meticulous annotation processes, extensive quality checks, and stringent transcription practices ensure that your AI models receive accurate and reliable data, leading to improved performance.
          </p>
        </div>
        <div className="service">
          <i class="fa-solid fa-pen-ruler"></i>
          <h2>Customized Solutions</h2>
          <p> We believe in tailoring our services to meet your unique requirements. Whether you need specific annotation methods, diverse data collection, or accurate transcription, our solutions are customized to align with your project's goals.
          </p>
        </div>
        <div className="service">
          <i class="fa-solid fa-clock"></i>
          <h2>Timely Delivery</h2>
          <p>
          We value your time and business goals. With our streamlined processes and efficient project management, we consistently deliver data on time, helping you meet your project milestones without delays.
          </p>
        </div>
        <div className="service">
          <i className="fas fa-mobile-alt"></i>
          <i class="fa-brands fa-cloudscale"></i>
          <h2>Scalability</h2>
          <p>
          We understand that your AI initiatives may evolve and grow. Our services are designed to scale seamlessly, accommodating increasing data volumes and diverse project complexities without compromising on quality.
          </p>
        </div>
        <div className="service">
          {/* <i className="fas fa-file-invoice"></i> */}
          <i class="fa-solid fa-shield-halved"></i>
          <h2>Security and Confidentiality </h2>
          <p>
          Your data security is paramount to us. We adhere to strict security protocols and ensure that your data remains confidential at all times. Our infrastructure is designed to safeguard your sensitive information.
          </p>
        </div>
        <div className="service">
          <i className="fas fa-money-check-alt"></i>
          <h2>Cost-Effective Solutions</h2>
          <p>
          Our competitive pricing ensures that you receive top-notch data services without breaking your budget. We offer cost-effective solutions to help you achieve your AI goals efficiently.
          </p>
        </div>
        <div className="service">
          {/* <i className="fas fa-network-wired"></i> */}
          <i class="fa-solid fa-face-smile"></i>
          <h2>Customer Satisfaction</h2>
          <p>
          Your satisfaction is our ultimate goal. We are dedicated to providing exceptional customer service, responding promptly to your inquiries, and ensuring a smooth and pleasant experience working with us.
          </p>
        </div>
        <div className="service">
        {/* <i class="fa-solid fa-hashtag"></i> */}
        <i class="fa-solid fa-people-arrows"></i>
          <h2>Social Impact</h2>
          <p>Transforming lives by creating job opportunities in rural India through AI data services, fostering social impact, and empowering communities for a brighter future.
          </p>
        </div>
      </div>
{/* <div className="bene_end"><p>At LabelX AI, we are driven by a passion for excellence and a commitment to empowering your AI projects with high-quality data. Partner with us, and let's embark on a journey of AI innovation together. Your success is our success
</p></div> */}
    </div>
    </>
  )
}

export default Bene
