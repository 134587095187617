import React, { useState, useEffect } from "react";
import "./Navigation.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../Assets/logo192.png"
// import Scrolltop from "../Scrolltop";
// import imagee from "../../Assets/data-label.avif"
const Navigation = () => {
  const [scrolled, setScrolled] = useState(false);
  // const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const handleLinkClick = () => {
  //   setMobileMenuOpen(false);
  // };
  const navigate = useNavigate();
  const handleLinkClickdirect = () => {
    const mobileMenuCheckbox = document.getElementById("menu-btn");
    mobileMenuCheckbox.checked = false;
  };
  // const handleDownload = (imageSrc) => {
  //   const link = document.createElement('a');
  //   link.href = imageSrc;
  //   link.download = 'image.jpg';
  //   link.target = '_blank';
  //   link.click();
  // };
  const handleScroll = () => {
    //scroll a
    const offset = window.scrollY;
    if (offset > 200) {
      setScrolled(true);
      // console.log("set true")
    } else {
      setScrolled(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  const handleLinkClickcheck = (className) => {
    // console.log(className);
    // if (window.location.pathname !== '/Services') {
    //   // If the current URL is not "/Services", navigate to "/Services"
    //   navigate('/Services');
    //   const MainfaqSection = document.getElementById(className);
    //   MainfaqSection.scrollIntoView({ behavior: 'smooth' });
    // } else {
    //   // If the current URL is "/Services", scroll to the element with the given className
    //   const MainfaqSection = document.getElementById(className);
    //   MainfaqSection.scrollIntoView({ behavior: 'smooth' });
    // }
    handleLinkClickdirect();
    navigate(`/Services#${className}`);
  };
  return (
    <>
      <nav className={`${scrolled ? "sticky-header" : ""}`}>
        <div className="wrapper">
          <div className="logo">
            <Link to="/"><img src={logo} className="logoone" alt="Labelx AI"></img></Link>
            {/* <Scrolltop/> */} 
          </div>
          <input type="radio" name="slider" id="menu-btn" />
          <input type="radio" name="slider" id="close-btn" />
          <ul className="nav-links">
            <label htmlFor="close-btn" className="btn close-btn">
              <i className="fas fa-times navi_close"></i>&nbsp; 
            </label>
            <li>
              <Link to="/Services" className="desktop-item">
                Services
              </Link>
              <input type="checkbox" id="showMega" />
              <label htmlFor="showMega" className="mobile-item">
                {/* <Link to="/Services" onClick={() => handleLinkClick()}>Services</Link>   */}
                Services
              </label>
              <div className="mega-box">
                <div className="content">
                  <div className="row">
                    <header>Data Labelling</header>
                    <ul className="mega-links">
                      <li>
                        <a
                          href="Services#image_annotation"
                          onClick={() =>
                            handleLinkClickcheck("image_annotation")
                          }
                        >
                          <i className="fa-solid fa-image"></i>&nbsp;Image Annotation
                        </a>
                      </li>
                      <li>
                        <a
                          href="Services#Text_labelling"
                          onClick={() => handleLinkClickcheck("Text_labelling")}
                        >
                          <i className="fa-solid fa-pen"></i>&nbsp;Text Annotation
                        </a>
                      </li>
                      <li>
                        <a
                          href="Services#Video_annotation"
                          onClick={() =>
                            handleLinkClickcheck("Video_annotation")
                          }
                        >
                          <i class="fa-solid fa-video"></i>&nbsp;Video
                          Annotation
                        </a>
                      </li>
                      <li>
                        <a
                          href="Services#audio_annotation"
                          onClick={() =>
                            handleLinkClickcheck("audio_annotation")
                          }
                        > 
                          <i class="fa-solid fa-music"></i>&nbsp;Audio
                          Annotation
                        </a>
                      </li>
                      <li>
                      <a
                          href="Services#sensor_annotation"
                          onClick={() =>
                            handleLinkClickcheck("sensor_annotation")
                          }
                        >
                          <i className="fa-sharp fa-solid fa-cloud-arrow-up"></i>&nbsp;
                          Sensor Data
                          </a>
                      </li>
                      <li>
                      <a
                          href="Services#first"
                          onClick={() =>
                            handleLinkClickcheck("first")
                          }
                        >
                          <i className="fa-solid fa-pen-ruler"></i>&nbsp;
                          Customer Specific</a>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <header>Data Sourcing</header>
                    <ul className="mega-links">
                      <li>
                        <a href="Services#image-data"
                          onClick={() =>
                            handleLinkClickcheck("image-data")
                          }>
                          <i className="fa-regular fa-images"></i>&nbsp;Image Data
                        </a>
                      </li>
                      <li>
                        <a href="Services#video-data"
                          onClick={() =>
                            handleLinkClickcheck("video-data")
                          }>
                          <i class="fa-solid fa-file-video"></i>&nbsp;Video Data
                        </a>
                      </li>
                      <li>
                        <a href="Services#audio-data"
                          onClick={() =>
                            handleLinkClickcheck("audio-data")
                          }>
                            <i className="fa-solid fa-file-audio"></i>&nbsp;Audio Data
                        </a>
                      </li>
                      <li>
                        <a href="Services#text-data"
                          onClick={() =>
                            handleLinkClickcheck("text-data")
                          }>
                          <i class="fa-solid fa-square-pen"></i>&nbsp;Text Data
                        </a>
                      </li>
                      <li>
                        <a href="Services#custom-source-data"
                          onClick={() =>
                            handleLinkClickcheck("custom-source-data")
                          }>
                          <i class="fa-solid fa-user-pen"></i>&nbsp;Custom Data
                        </a>
                      </li>
                      {/* <li>
                        <a href="Services#delivery"
                          onClick={() =>
                            handleLinkClickcheck("delivery")
                          }>
                          <i class="fa-solid fa-truck"></i>&nbsp;  Our Delivery
                        </a>
                      </li> */}
                    </ul>
                  </div>
                  <div className="row">
                    <header>Transcription Services</header>
                    <ul className="mega-links">
                      <li>
                        <a href="Services#Video_Transcription"
                          onClick={() =>
                            handleLinkClickcheck("Video_Transcription")
                          }>
                          <i class="fa-solid fa-video"></i>&nbsp;
                          Video 
                        </a>
                      </li>
                      <li>
                        <a href="Services#Audio_Transcription"
                          onClick={() =>
                            handleLinkClickcheck("Audio_Transcription")
                          }>
                          <i class="fa-solid fa-music"></i>&nbsp;Audio
                        </a>
                      </li>
                      <li>
                        <a href="Services#Multilingual_Transcription"
                          onClick={() =>
                            handleLinkClickcheck("Multilingual_Transcription")
                          }>
                          <i class="fa-solid fa-language"></i>&nbsp;Multilingual
                        </a>
                      </li>
                      <li>
                        <a href="Services#Time-Stamped_Transcription"
                          onClick={() =>
                            handleLinkClickcheck("Time-Stamped_Transcription")
                          }>
                          <i class="fa-solid fa-clock"></i>&nbsp;Time-Stamped 
                        </a>
                      </li>
                      <li>
                        <a href="Services#captions"
                          onClick={() =>
                            handleLinkClickcheck("captions")
                          }>
                          <i class="fa-solid fa-closed-captioning"></i>&nbsp;Captioning 
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link to="/industry" className="desktop-item">
                Industry
              </Link>
              <input type="checkbox" id="showMega2" />
              <label htmlFor="showMega2" className="mobile-item">
                Industry
              </label>
              <div className="mega-box">
                <div className="content2">
                  <div className="row">
                    {/* <header><i class="fa-solid fa-notes-medical"></i>&nbsp;   Healthcare and Medical:</header> */}
                    <ul className="mega-links">
                      <li>
                        <Link to="/industry/health" onClick={()=>handleLinkClickdirect()}>
                          <i className="fa-solid fa-notes-medical"></i>&nbsp; {" "}
                          Healthcare
                        </Link>
                      </li>
                      <li>
                        <Link to="/industry/automotive"  onClick={()=>handleLinkClickdirect()}>
                          <i className="fa-solid fa-car"></i>&nbsp;  Automotive
                        </Link>
                      </li>
                      <li>
                        <Link to="/industry/finance"  onClick={()=>handleLinkClickdirect()}>
                          <i className="fa-solid fa-building-columns"></i>&nbsp; {" "}
                          Finance
                        </Link>
                      </li>
                      <li>
                        <Link to="/industry/ecommerce"  onClick={()=>handleLinkClickdirect()}>
                          <i className="fa-solid fa-cash-register"></i>&nbsp;  Retail
                        </Link>
                      </li>
                      <li>
                        <Link to="/industry/media"  onClick={()=>handleLinkClickdirect()}>
                        <i class="fa-brands fa-medium"></i>&nbsp;  Media 
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/industry/travel"  onClick={()=>handleLinkClickdirect()}>
                        <i class="fa-solid fa-location-dot"></i>&nbsp;  Travel & Tourism
                        </Link>
                      </li> */}
                     <li className="desktop_view_ind">
                      <Link to="/industry"  onClick={()=>handleLinkClickdirect()}>
                        All Industries <tb/>
                          <i
                            className="fa-sharp fa-solid fa-arrow-right fa-bounce fa-xl"
                            style={{ color: "#2869bd", "font-size":17}}
                          />
                        </Link>
                      </li>
                     
                    </ul>
                  </div>
                  <div className="row">
                    {/* <header>Email Services</header> */}
                    <ul className="mega-links">
                      <li>
                        <Link to="/industry/manufacturer"  onClick={()=>handleLinkClickdirect()}>
                          <i className="fa-solid fa-industry"></i>&nbsp; Industrial
                        </Link>
                      </li>

                      <li>
                        <Link to="/industry/agriculture"  onClick={()=>handleLinkClickdirect()}>
                          <i className="fa-solid fa-leaf"></i>&nbsp;  Agriculture
                        </Link>
                      </li>
                      <li>
                        <Link to="/industry/energy" onClick={()=>handleLinkClickdirect()}>
                          <i className="fa-solid fa-bolt"></i>&nbsp;  Energy
                        </Link>
                      </li>
                      <li>
                        <Link to="/industry/sports"  onClick={()=>handleLinkClickdirect()}>
                          <i className="fa-solid fa-volleyball"></i>&nbsp;  Sports
                        </Link>
                      </li>
                      <li>
                        <Link to="/industry/education"  onClick={()=>handleLinkClickdirect()}>
                        <i class="fa-solid fa-school"></i>&nbsp; Education
                        </Link>
                      </li>
                      <li className="mobile_view_ind">
                      <Link to="/industry"  onClick={()=>handleLinkClickdirect()}>
                        All Industries <tb/>
                          <i
                            className="fa-sharp fa-solid fa-arrow-right fa-bounce fa-xl"
                            style={{ color: "#2869bd", "font-size":17}}
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <Link to="/company" className="desktop-item">
                Company
              </Link>
              <input type="checkbox" id="showDrop" />
              <label htmlFor="showDrop" className="mobile-item">
                Company
              </label>
              <ul className="drop-menu">
                <li>
                  <Link to="/company" onClick={() => handleLinkClickdirect()}>
                  <img width="20" height="20" src="https://img.icons8.com/ios/50/about.png" alt="about"/> About us</Link>
                </li>
                {/* <li>
                  <Link to="/team" onClick={() => handleLinkClickdirect()}>
                    Meet Our Team
                  </Link>
                </li> */}
                <li>
                  <Link to="/join_us" onClick={() => handleLinkClickdirect()}>
                  <img width="20" height="20" src="https://img.icons8.com/ios/50/permanent-job.png" alt="permanent-job"/> Join us
                  </Link>
                </li>
                <li>
                  <Link to="/workflow" onClick={handleLinkClickdirect}>
                  <img width="20" height="20" src="https://img.icons8.com/ios/50/workflow.png" alt="workflow"/>   Workflow
                  </Link>
                </li>
                <li>
                  <Link to="/security" onClick={handleLinkClickdirect}>
                  <img width="20" height="20" src="https://img.icons8.com/ios/50/cyber-security.png" alt="cyber-security"/>  Security
                  </Link>
                </li>
                
              </ul>
            </li>
            <li>
              <Link to="/pricing" onClick={() => handleLinkClickdirect()}>
                Pricing
              </Link>
            </li>
            <li>
              {/* <Link to="/blog" onClick={() => handleLinkClickdirect()}> */}
              <Link to="/blog" className="desktop-item">
                Resources
              </Link>
              <input type="checkbox" id="showDrop2" />
              <label htmlFor="showDrop2" className="mobile-item">
                Resources
              </label>
              <ul className="drop-menu">
                <li>
                  <Link to="/blog" onClick={() => handleLinkClickdirect()}>
                  <img width="20" height="20" src="https://img.icons8.com/external-wanicon-lineal-wanicon/64/external-blogs-digital-content-wanicon-lineal-wanicon.png" alt="external-blogs-digital-content-wanicon-lineal-wanicon"/> Blog</Link>
                </li>
                {/* <li>
                  <Link to="/team" onClick={() => handleLinkClickdirect()}>
                    Meet Our Team
                  </Link>
                </li> */}
                <li>
                  <Link to="/faq" onClick={() => handleLinkClickdirect()}>
                  <img width="20" height="20" src="https://img.icons8.com/ios/50/faq.png" alt="faq"/> FAQ
                  </Link>
                </li>
                {/* <li>
                  <Link to="/usecases" onClick={handleLinkClickdirect}>
                    Use Cases
                  </Link>
                </li> */}
              </ul>
              {/* </Link> */}
            </li>
            <li>
              <Link to="/contactus" onClick={() => handleLinkClickdirect()}>
                Contact us
              </Link>
            </li>
          </ul>
          <ul>
            <li
            // className="nav-links"
            //   onClick={() => {
            //     setIsNavExpanded(!isNavExpanded);
            //   }}
            >
              <Link to="/freesample" className="desktop-item">
                <button className="custom-button pulse">Free Sample</button>
              </Link>
            </li>
          </ul>
          <label htmlFor="menu-btn" className="btn menu-btn">
            {/* <i className="fas fa-bars"></i>&nbsp;  */}
            <i className="fa-solid fa-bars navi_color"></i>&nbsp; 
          </label>
        </div>
      </nav>
    </>
  );
};

export default Navigation;
