import React from "react";
import "./Security.css"; // Import CSS file for styling
import {motion} from "framer-motion"
import Access_control from "../../Assets/Security/access.jpg"
import Audits from "../../Assets/Security/Audits.jpg"
import Compliance from "../../Assets/Security/Compliances.jpg"
import Confidential from "../../Assets/Security/Confidential.jpg"
import dataPrivacyImage from "../../Assets/Security/Data_pri.jpg"
import securityhandle from "../../Assets/Security/security_handling.jpg"
import securityinfra from "../../Assets/Security/security_infra.jpg"
import incicdent from "../../Assets/Security/fbi_device_1.jpg"
import dataencrypt from "../../Assets/Security/data_encrypt.jpg"
const SecurityPage = () => {
  return (
    <>
      <div className="temp"></div>
      <div className="security-page ">
        <h1 className="security-title">Security and Data Confidentiality</h1>
        <div className="security-content">
          <p>
            At LabelX AI, we prioritize the security and confidentiality of your
            data. We understand the sensitive nature of the information you
            entrust to us and take extensive measures to ensure its protection
            throughout our processes. Here's an overview of our security
            practices:
          </p>

          <motion.div className='videoText'
      initial={{x:"100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:1,delay:0.6,ease:'easeOut'}}
      >
          <div className="security-section reverse">
            <div className="security-section-image">
              <img src={securityinfra} alt="Secure Infrastructure" />
            </div>
            <div className="security-section-content">
              <h2 className="security-section-title">Secure Infrastructure</h2>
              <p className="security-section-description">
                We maintain a robust and secure infrastructure to safeguard your
                data. Our systems are hosted on trusted cloud platforms with
                industry-leading security features, ensuring the
                confidentiality, integrity, and availability of your
                information.
              </p>
            </div>
          </div>
          </motion.div>

          <motion.div 
      initial={{x:"-100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"-100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}
      >
          <div className="security-section">
            <div className="security-section-image">
              <img src={dataPrivacyImage} alt="Data Privacy" />
            </div>
            <div className="security-section-content">
              <h2 className="security-section-title">Data Privacy</h2>
              <p className="security-section-description">
                We adhere to strict data privacy standards and comply with
                applicable data protection regulations. Your data is handled
                with the utmost care, and we only collect, process, and store it
                for the purposes defined in our agreement with you. We do not
                share your data with any third parties without your explicit
                consent.
              </p>
            </div>
          </div>
          </motion.div>


          <motion.div className='videoText'
      initial={{x:"100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:1,delay:0.6,ease:'easeOut'}}
      >

          <div className="security-section reverse">
            <div className="security-section-image">
              <img src={Access_control} alt="Data Privacy" />
            </div>
            <div className="security-section-content">
              <h2 className="security-section-title">Access Control</h2>
              <p className="security-section-description">
                Access to your data is limited to authorized personnel who
                require it for their specific tasks. We implement role-based
                access control and regularly review and update access privileges
                to ensure that only the necessary individuals have access to
                your data.
              </p>
            </div>
          </div>
          </motion.div>



          <motion.div 
      initial={{x:"-100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"-100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}
      >
          <div className="security-section ">
            <div className="security-section-image">
              <img src={Confidential} alt="Data Privacy" />
            </div>
            <div className="security-section-content">
              <h2 className="security-section-title">
                Confidentiality Agreements
              </h2>
              <p className="security-section-description">
                All our employees and contractors sign confidentiality
                agreements, reinforcing their commitment to maintaining the
                confidentiality and security of your data. These agreements
                ensure that any information shared during the course of our
                engagement remains strictly confidential.
              </p>
            </div>
          </div>
          </motion.div>

          <motion.div className='videoText'
      initial={{x:"100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:1,delay:0.6,ease:'easeOut'}}
      >
          <div className="security-section reverse">
            <div className="security-section-image">
              <img src={dataencrypt} alt="Data Privacy" />
            </div>
            <div className="security-section-content">
              <h2 className="security-section-title">Data Encryption</h2>
              <p className="security-section-description">
                We employ industry-standard encryption techniques to protect
                your data during transmission and at rest. This ensures that
                your information remains secure and confidential, both while in
                transit and when stored in our systems.
              </p>
            </div>
          </div>
          </motion.div>

          <motion.div 
      initial={{x:"-100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"-100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}
      >
          <div className="security-section">
            <div className="security-section-image">
              <img src={securityhandle} alt="Data Privacy" />
            </div>
            <div className="security-section-content">
              <h2 className="security-section-title">Secure Data Handling</h2>
              <p className="security-section-description">
                Our data handling processes adhere to best practices for
                security. We maintain strict protocols for data transfer,
                storage, and deletion to prevent unauthorized access, accidental
                loss, or misuse of your data.
              </p>
            </div>
          </div>
          </motion.div>

          <motion.div className='videoText'
      initial={{x:"100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:1,delay:0.6,ease:'easeOut'}}
      >
          <div className="security-section reverse">
            <div className="security-section-image">
              <img src={Audits} alt="Data Privacy" />
            </div>
            <div className="security-section-content">
              <h2 className="security-section-title">
                Regular Audits and Assessments
              </h2>
              <p className="security-section-description">
                We regularly conduct internal audits and assessments of our
                security practices to ensure compliance with industry standards.
                We continuously monitor and improve our security measures to
                stay up to date with evolving threats and maintain the highest
                level of protection for your data.
              </p>
            </div>
          </div>
          </motion.div>

          <motion.div 
      initial={{x:"-100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"-100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:0.5,delay:0.3,ease:'easeOut'}}
      >
          <div className="security-section">
            <div className="security-section-image">
              <img src={Compliance} alt="Data Privacy" />
            </div>
            <div className="security-section-content">
              <h2 className="security-section-title">Compliance</h2>
              <p className="security-section-description">
                We are committed to complying with all relevant data protection
                laws and regulations. We stay informed about the latest legal
                and regulatory requirements and make necessary adjustments to
                our practices to ensure compliance.
              </p>
            </div>
          </div>
          </motion.div>

          <motion.div className='videoText'
      initial={{x:"100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:1,delay:0.6,ease:'easeOut'}}
      >
          <div className="security-section reverse">
            <div className="security-section-image">
              <img src={incicdent} alt="Data Privacy" />
            </div>
            <div className="security-section-content">
              <h2 className="security-section-title">Incident Response</h2>
              <p className="security-section-description">
                In the unlikely event of a security incident or data breach, we
                have a robust incident response plan in place. Our team is
                prepared to respond promptly and effectively, taking necessary
                actions to mitigate the impact, notify relevant parties, and
                implement corrective measures.
              </p>
            </div>
          </div>
        </motion.div>
        </div>
      </div>
    </>
  );
};

export default SecurityPage;
