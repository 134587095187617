import React, { useState, useEffect } from "react";
import "./ser.css";
import x from "../../Assets/labelling.jpg";
import data_anno from "../../Assets/data_annotaion.jpg";
import video_anno from "../../Assets/video_anno.jpg";
import audio_anno from "../../Assets/audio_anno.jpg";
import sensor_anno from "../../Assets/sensor_anno.jpg";
import expertise from "../../Assets/expertise.jpg"
import quality from "../../Assets/quality.jpg"
import delivery from "../../Assets/delivery.jpg"
// import transcript from "../../Assets/transcription.jpg"
const ServiceSection = ({ title, description, image, reverse, idd }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const getImage = async () => {
      try {
        const importedImage = await import(`../../Assets/${image}`);
        setImageUrl(importedImage.default);
      } catch (error) {
        console.error(`Error importing image: ${image}`, error);
      }
    };

    getImage();
  }, [image]);

  return (
    <div className={`service-section ${reverse ? "reverse" : ""}`}>
      <div className="service-section-image" id={idd}>
        {imageUrl && <img src={imageUrl} alt={title} />}
      </div>
      <div className="service-section-content">
        <h2 className="service-section-title">{title}</h2>
        <p className="service-section-description">{description}</p>
      </div>
    </div>
  );
};

const OurServices = () => {
  return (
    <div className="our-services">
      {/* <h1 className="page-title">Our Services</h1> */}

      <ServiceSection
        title="Data Labelling"
        description="At LabelX AI, we offer comprehensive data labeling services to help train and improve the accuracy of your AI models. Our expert annotators meticulously annotate your data, ensuring high-quality labeled datasets that are crucial for machine learning algorithms. With our precise and consistent data labeling, we enable your AI models to make accurate predictions and deliver actionable insights."
        image="data-label.avif"
        idd="data-aannoo"
      />
      <div className="space"></div>
      <div className="services_title_type">
        <div className="head_service">
          <h2>Types of Data Labeling</h2>
        </div>
        <p>
          We specialize in a wide range of data labeling techniques to
          accommodate diverse AI applications. Our data labeling services
          include <span className="service_colon"></span>
        </p>
      </div>

      <div className="service-section reverse" id="image_annotation">
        <div className="service-section-image">
          <img src={x} alt="temper" />
        </div>
        <div className="service-section-content">
          <h2 className="service-section-title">Image Annotation</h2>
          <ul>
            <li>
              <span className="headline_image-anno">Object Detection </span> We
              annotate objects of interest within an image and draw bounding
              boxes around them. Polygon Annotation We trace precise contours
              around objects with irregular shapes using polygon annotation.
            </li>
            <li>
              <span className="headline_image-anno">
                Semantic Segmentation
              </span>{" "}
              We assign pixel-level labels to different regions or objects
              within an image. Instance Segmentation We annotate each instance
              of an object within an image, providing pixel-level distinction.
            </li>
            <li>
              <span className="headline_image-anno">Keypoint Annotation </span>
              We identify and mark specific keypoints on objects, enabling
              accurate pose estimation or feature tracking.
            </li>
            <li>
              <span className="headline_image-anno">Image Classification</span>{" "}
              We assign class labels to images based on their content or
              characteristics.
            </li>
          </ul>
        </div>
      </div>

      <div className="service-section" id="Text_labelling">
        <div className="service-section-image">
          <img src={data_anno} alt="temper" />
        </div>
        <div className="service-section-content">
          <h2 className="service-section-title">Text Labeling </h2>
          <ul>
            <li>
              <span className="headline_image-anno">Sentiment Analysis </span>
              We annotate text for sentiment polarity, classifying it as
              positive, negative, or neutral.
            </li>
            <li>
              <span className="headline_image-anno">Named Entity
              Recognition </span>We identify and annotate named entities such as
              persons, organizations, locations, and dates.
            </li>
            <li>
              <span className="headline_image-anno">Entity Linking </span>We
              link named entities in text to their corresponding entities in a
              knowledge base or database.
            </li>
            <li>
              <span className="headline_image-anno">
                Text Categorization 
              </span>
              We assign category labels to text documents based on their topic
              or theme.
            </li>
            <li>
              <span className="headline_image-anno">Text Summarization </span>
              We generate concise summaries of longer text passages,
              highlighting key information.
            </li>
            <li>
              <span className="headline_image-anno">
                Document Classification 
              </span>
              We classify whole documents into predefined categories or classes.
            </li>
          </ul>
        </div>
      </div>

      <div className="service-section reverse" id="Video_annotation">
        <div className="service-section-image">
          <img src={video_anno} style={{ scale: "0.7" }} alt="temper" />
        </div>
        <div className="service-section-content">
          <h2 className="service-section-title">Video Annotation</h2>
          <ul>
            <li>
              <span className="headline_image-anno">Action Recognition </span>
              We annotate video frames to identify and label specific actions or
              activities.
            </li>
            <li>
              <span className="headline_image-anno">Object Tracking </span> We
              track and annotate the movement of objects across consecutive
              video frames.
            </li>
            <li>
              <span className="headline_image-anno">Event Detection</span>We
              identify and annotate specific events or occurrences within a
              video sequence.
            </li>
            <li>
              <span className="headline_image-anno">
                Scene Understanding
              </span>
              We annotate video frames to provide context and understanding of
              the overall scene.
            </li>
            <li>
              <span className="headline_image-anno">Video Captioning </span>We
              generate descriptive captions or subtitles for videos, summarizing
              their content.
            </li>
          </ul>
        </div>
      </div>

      <div className="service-section" id="audio_annotation">
        <div className="service-section-image">
          <img src={audio_anno} style={{}} alt="temper" />
        </div>
        <div className="service-section-content">
          <h2 className="service-section-title">Audio Annotation  </h2>
          <ul>
            <li>
              <span className="headline_image-anno">Speech Recognition  </span>
              We transcribe spoken words or phrases into written text.
            </li>
            <li>
              <span className="headline_image-anno">
                Speaker Diarization 
              </span>
              We identify different speakers within an audio recording and
              assign labels to each speaker.
            </li>
            <li>
              <span className="headline_image-anno">
                Sound Classification
              </span>
              We label audio segments with their corresponding sound classes or
              categories.
            </li>
            <li>
              <span className="headline_image-anno">Audio Segmentation </span>
              We divide audio recordings into segments based on different
              events, speakers, or time intervals.
            </li>
            <li>
              <span className="headline_image-anno">Emotion Analysis </span>We
              annotate audio data to identify and classify different emotions
              expressed
            </li>
          </ul>
        </div>
      </div>

      <div className="service-section reverse" id="sensor_annotation">
        <div className="service-section-image">
          <img src={sensor_anno} style={{ scale: "0.8" }} alt="temper" />
        </div>
        <div className="service-section-content">
          <h2 className="service-section-title">Sensor Data Annotation </h2>
          <ul>
            <li>
              <span className="headline_image-anno">
                LiDAR Point Cloud Annotation 
              </span>
              We label and annotate 3D point clouds captured by LiDAR sensors
              for object detection or mapping.
            </li>
            <li>
              <span className="headline_image-anno">
                Radar Data Annotation 
              </span>
              We annotate radar data to identify and track objects based on
              their radar signatures.
            </li>
            <li>
              <span className="headline_image-anno">
                GPS Data Annotation 
              </span>
              We label GPS data to extract information about location, speed,
              and other geospatial attributes.
            </li>
          </ul>
        </div>
      </div>
      <ServiceSection
        title="Customer-Specific Annotation"
        description="At LabelX AI, we understand that each customer may have unique requirements when it comes to data annotation. We offer customer-specific annotation services tailored to your specific needs and use cases. Whether you require domain-specific labeling guidelines, custom annotation methodologies, or specialized data formats, our team is equipped to handle your specific annotation requirements."
        image="customer_spec.jpg"
        idd="first"
      />
      <ServiceSection
        title="Client Specific"
        description="We collaborate closely with our clients to understand their specific annotation needs, ensuring that the labeled datasets we provide align with their specific objectives and application scenarios. Our experienced annotators follow customer-specific instructions, guidelines, or labeling standards to ensure accurate and relevant annotations for your AI model"
        image="cust-spec-2.jpg"
        idd="second"
        reverse={true}
      />

      {/* <ServiceSection
        title=""
        description="Whether you operate in a niche industry, have specific annotation criteria, or require annotations for proprietary data, our customer-specific annotation services are designed to meet your unique needs. We work closely with you to develop a deep understanding of your requirements, providing"
        image="cust-spec-3.jpg"
        idd="third"
      />
        <h2>Industries We Serve</h2>
        <p>
          Our data labeling services cater to a wide range of industries and use
          cases. Here are additional industries we serve
        </p>
      <div className="weserves">
        <div className="right_serves">
          <ul>
            <li>Manufacturing and Industrial Automation</li>
            <li>Insurance and Risk Assessment</li>
            <li>Energy and Utilities</li>
            <li>Agriculture and Farming</li>
            <li>Transportation and Logistic</li>
            <li>Social Media and Customer Analytic</li>
          </ul>
        </div>
        <div className="left_serves">
        <ul>
          <li>Healthcare and Medical Research</li>
          <li>Financial Services and Banking</li>
          <li>E-commerce and Retail</li>
          <li>Media and Entertainment</li>
          <li>Government and Public Sector</li>
          <li>Education and EdTech </li>
        </ul>
        </div> */}
      {/* </div> */}
<div className="space"></div>
      <ServiceSection
        title="Data Sourcing"
        description="At LabelX AI, we specialize in sourcing high-quality data for a wide range of industries and AI applications. Our data sourcing services encompass various data types, including images, videos, audio, text, and more. We understand the critical role that quality data plays in the success of AI models, and we are committed to providing you with the most relevant, diverse, and reliable datasets to fuel your AI projects."
        image="data-source.jpg"
        idd="data-sourcing"
      />

<div className="service-section reverse" id="sensor_annotation">
        <div className="service-section-image">
          <img src={expertise} style={{ scale: "0.8" }} alt="temper" />
        </div>
        <div className="service-section-content">
          <h2 className="service-section-title">Data Sourcing Expertise </h2>
          <p className="service-section-description">We have extensive expertise in sourcing data across different domains, enabling us to cater to diverse industries and specific use cases. Whether you require industry-specific images, multimedia data, or text corpora, our team is equipped to handle your unique data sourcing needs.</p>
      </div>
      </div>


      <ServiceSection
        title="Image Data"
        description="We source a vast array of image data to support computer vision applications. Our image data sourcing covers a wide range of categories, including object recognition, image classification, segmentation, and more. From specialized datasets for healthcare and retail to generic image collections, we curate and deliver the datasets that align with your project requirements."
        image="image_data.jpg"
        idd="image-data"
      />
        <div className="space"></div>
        <ServiceSection
          title="Audio Data"
          description="Our expertise extends to sourcing audio data for speech recognition, natural language processing, and audio analysis applications. We can provide you with carefully curated audio datasets that encompass different languages, accents, and speech variations, enabling you to train and develop robust AI models."
          image="audio_anno.jpg"
          idd="audio-data"
          reverse={true}
        />
      <ServiceSection
        title="Video Data"
        description="For AI projects that involve video analysis, our data sourcing services provide access to a rich collection of video data. Whether you need action recognition, scene understanding, or video object tracking, we can source diverse video datasets that capture various environments, scenarios, and activities."
        image="video-sou.jpg"
        idd="video-data"
      />
     
      <ServiceSection
        title="Text Data"
        description="Textual data is vital for a wide range of AI applications, including natural language processing, sentiment analysis, and information retrieval. Our data sourcing services include procuring large-scale text corpora, domain-specific textual data, and annotated text datasets, ensuring you have the right resources."
        image="data-sou.jpg"
        idd="text-data"
        reverse={true}
      />

      <ServiceSection
        title="  Custom Data Sourcing"
        description="We understand that every AI project has unique requirements. If you need specialized data that is specific to your industry or use case, our team can work closely with you to curate and source custom datasets that align with your project objectives."
        image="custom-source.jpg"
        idd="custom-source-data"
        // reverse={true}
      />
<div className="space"></div>
<div className="service-section reverse" id='qual'>
      <div className="service-section-image" id="diversity">
        <img src={quality} alt="divv" />
      </div>
      <div className="service-section-content">
        <h2 className="service-section-title">Quality and Diversity</h2>
        <p className="service-section-description">We place a strong emphasis on data quality and diversity. Our data sourcing process includes rigorous evaluation and validation to ensure that the data meets the highest quality standards. We strive to provide diverse datasets that capture various demographics, scenarios, and contexts to enhance the performance and generalization capabilities of your AI models.</p>
      </div>
    </div>
<div className="service-section" >
      <div className="service-section-image" id="delivery">
        <img src={delivery} alt="divv" />
      </div>
      <div className="service-section-content">
        <h2 className="service-section-title">Flexible Delivery Options</h2>
        <p className="service-section-description">We offer flexible delivery options tailored to your needs. Whether you require a one-time dataset delivery or ongoing data sourcing support, we can accommodate your requirements and ensure timely delivery of the data you need to power your AI projects.</p>
      </div>
    </div>

    
    <ServiceSection
        title="Transcription Services"
        description="At LabelX AI, we provide accurate and efficient transcription services to convert your audio and video content into written text. Transcription is a crucial step in making audio and video data accessible, searchable, and analyzable, enabling you to leverage valuable insights for your AI projects. Our team of skilled transcribers ensures the highest level of accuracy and attention to detail in every transcription project we undertake."
        image="transcription.jpg"
        idd="transcription"
        reverse={true}
      />
      <div className="space" id="Audio_Transcription"></div>
       <ServiceSection
        title="Audio Transcription"
        description="Our audio transcription service involves converting spoken language in audio files into written text. Whether you have interviews, podcasts, conference calls, or any other type of audio content, we can transcribe it into a readable format. We use both manual transcription and automated speech recognition (ASR) technology to ensure accuracy and efficiency in handling your audio data."
        image="audio_trans.jpg"
        idd="Audio_Transcriptions"
      />
      <div className="space"></div>
       <ServiceSection
        title="Video Transcription"
        description="Video transcription involves transforming spoken words and dialogue in video content into written text. This makes it easier to navigate, search, and understand the video's content. Our video transcription process includes a combination of manual transcription and advanced speech recognition algorithms, guaranteeing reliable results."
        image="video_trans.jpg"
        idd="Video_Transcription"
        reverse={true}
      />
      {/* <div className="space"></div> */}
       <ServiceSection
        title="Multilingual Transcription"
        description="In an increasingly globalized world, multilingual content is becoming more prevalent. Our multilingual transcription service caters to audio and video content in multiple languages, ensuring that language barriers do not hinder your data analysis and AI projects. We have a team of expert transcribers proficient in various languages, delivering accurate and culturally appropriate transcriptions."
        image="multi.jpg"
        idd="Multilingual_Transcription"
        // reverse={true}
      />
       <ServiceSection
        title="Time-Stamped Transcription"
        description="For content that requires precise time references, such as legal depositions, educational lectures, or media interviews, we provide time-stamped transcriptions. Each segment of the transcript is associated with a specific time code, allowing you to pinpoint and reference particular moments in the audio or video."
        image="timed.avif"
        idd="Time-Stamped_Transcription"
        reverse={true}
      />
       <ServiceSection
        title="Captioning and Subtitling"
        description="We offer captioning and subtitling services for video content, adding synchronized text to enhance accessibility and user experience. Captions are beneficial for the hearing-impaired and can improve engagement in various online platforms and educational materials."
        image="caption.jpg"
        idd="captions"
        // reverse={true}
      />
{/* •	•	•	•	• • • •	•	•	•	•	•	•	• • • •	•	•	• • • •	•	•	 */}
{/* 
<h2>Industries We Serve </h2>
<div className="weservetrans">
        <p>
Our transcription services cater to a wide range of industries, including but not limited to
        </p>
          <ul>
            <li><span className="headline_image-anno">Media and Entertainment</span> Transcribing interviews, podcasts, and video content for media companies and content creators.</li>
            <li><span className="headline_image-anno">Education</span>Providing transcriptions for educational materials, lectures, and online courses.</li>
            <li><span className="headline_image-anno">Legal Time-stamped</span> transcriptions for court proceedings, depositions, and legal documentation.</li>
            <li><span className="headline_image-anno">Healthcare</span> Transcribing medical dictations, patient records, and healthcare-related content.</li>
            <li><span className="headline_image-anno">Market Research</span> Transcribing focus group discussions, interviews, and survey responses for market research purposes.</li>
            <li><span className="headline_image-anno">Corporate</span> Transcribing conference calls, meetings, and corporate presentations for efficient communication and documentation.</li>
            <li><span className="headline_image-anno">E-Learning</span> Captioning and subtitling video content for e-learning platforms and educational resources.</li>
            <li><span className="headline_image-anno">Financial Services</span> Transcribing financial reports, shareholder meetings, and earnings calls.</li>
            <li><span className="headline_image-anno">Government Providing</span> transcription services for government agencies and public meetings.</li>
            <li><span className="headline_image-anno">Technology</span> Transcribing software demonstrations, technical presentations, and webinars.</li>
            <li><span className="headline_image-anno">Nonprofit Organizations</span> Transcribing interviews and meetings for nonprofit organizations and NGOs.</li>
          </ul>
      </div> */}
    </div>
  );
};

export default OurServices;
