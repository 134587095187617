import React, { useState } from "react";
import "./Blog.css";
import allblog from "../../data";
import { Link } from "react-router-dom";
import Blogslide from "../../Components/Upperslider/Blogslide";
import { motion, AnimatePresence } from "framer-motion";

const Blog = () => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  // Filter blogs based on selected category
  const filteredBlogs =
    selectedCategory === "All"
      ? allblog
      : allblog.filter((blog) => blog.category === selectedCategory);

  // Get unique categories from blogs
  const categories = Array.from(
    new Set(allblog.map((blog) => blog.category))
  );

  // Handle category selection
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
  };
  return (
    <>
      <div className="temp"></div>
      <div className="header_blog">
        <h1>Our Blogs</h1>
      </div>
      <Blogslide />
       <motion.div
        className="videoText"
        initial={{ y: "0", opacity: 0 }}
        whileInView={{ x: 0, opacity: 1 }}
        exit={{ y: "-100%", opacity: 0, transition: { duration: 0, delay: 0 } }}
        transition={{ duration: 0.5, delay: 1, ease: "easeOut" }}
      >
        {/* <div className="category-buttons-filter">
          <button
            className={selectedCategory === "All" ? "active_btn_filter" : "other_btn"}
            onClick={() => handleCategorySelect("All")}
          >
            All
          </button> */}
          {/* {categories.map((category) => (
            <button
              key={category}
              className={selectedCategory === category ? "active_btn_filter" : "other_btn"}
              onClick={() => handleCategorySelect(category)}
            >
              {category}
            </button>
          ))} */}
        {/* </div> */}
        <div className="whole_blog">
          <div className="wrapper_blog">
            <AnimatePresence>
              {filteredBlogs.map((blog) => (
                <motion.div
                  className="card"
                  key={blog.title}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <div className="poster">
                    <img
                      src={require(`../../Assets/${blog.poster}`)}
                      alt="Location Unknown"
                    />
                  </div>
                  <div className="details">
                    <h1>{blog.title}</h1>
                    <h2>• {blog.publish_date}</h2>
                    <div className="tag_blogs">
                      <span className="tag_blog yellow">{blog.category}</span>
                    </div>
                    <p className="desc">{blog.small_desc}</p>
                    {/* <div className="cast">
                      <h3>Author</h3>
                      <h2>{blog.author_name}</h2>
                    </div> */}
                    <Link
                      to={blog.url}
                      className="card"
                      key={blog.title}
                    >
                      <div className="btn_blog">
                        <button>Read More...</button>
                      </div>
                    </Link>
                  </div>
                </motion.div>
              ))}
            </AnimatePresence>
          </div>
        </div>
      </motion.div> 
    </>
  );
};

export default Blog;
