import React, { useState, useRef } from "react";
import './faq.css'
import axios from "axios"
import emailjs from '@emailjs/browser';
import {AnimatePresence, motion} from 'framer-motion'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Scrolltop from "../../Components/Scrolltop";
// import Teamcard from "../../Components/Team_cards/Teamcard";
import Lottie from "lottie-react"
import faqdata from "../../Assets/faq.json"
function Faq(){

  const [emailuser, setemail] = useState("");
  const [nameuser, setname] = useState("");
  const [questionuser, setquestion] = useState("");

  const onChange = (e) => {
    setemail(e.target.value);
    // console.log(query);
};
  const namechange = (e) => {
    setname(e.target.value);
    // console.log(query);
};
  const questionchange = (e) => {
    setquestion(e.target.value);
    // console.log(query);
};

  function isValidEmail(email) {
    const emailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    return emailFormat.test(email);
  }
  const form = useRef();
  const sendEmail = async(e) =>{
    e.preventDefault();
    if(isValidEmail(emailuser))
    {
      toast.warning("Processin Your Request....",{
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      // theme:"dark",
      className: 'toast-custom-style'});
      
      try {
        const config = {
          headers: {
            "Content-type": "application/json",
          },
        };
        const { data } = await axios.post(
          "https://website-xi-henna-33.vercel.app/api/v1/forms/Question",
          {
            name:nameuser,
            email:emailuser,
            question:questionuser
          },
          config
        );
        console.log(data);
      }
  catch(error)
{
  toast.warning({
    title: "Error Occured!",
    description: error.response.data.message,
    status: "error",
    duration: 5000,
    isClosable: true,
    position: "bottom",
  });
  setname("");
  setemail("");
  setquestion("");
  return;
}
      await emailjs.sendForm('service_j7co5ja', 'template_o351d8m', form.current, 'BztxLy-9XorlierDN')
      .then((result) => {
          console.log(result.text);
          toast.success('Thank you, Your Question has been Submitted will get back to you!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme:"dark",
            className: 'toast-custom-style',
          });
      },(error) => {
          console.log(error.text);
          toast.error('Sorry!!! , Some Error Occurred, Please Try Again', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            // theme:"dark",
            className: 'toast-custom-style',
          });
      });
    }
    else{
      toast.warning("Please Enter a Valid Email and then Retry", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme:"dark",
        className: 'toast-custom-style',
      });
    }
    form.current.reset();
    // alert("Submitted!")
    setname("");
    setemail("");
    setquestion("");
    }
    
  
    const [selected, setSelected] = useState(null)
    const toggle = (i) => {
      if (selected ===  i){
        return setSelected(null)
      }
      setSelected(i)
    }
    // const mailcheckerandsender=(event)=>{
    //   if(isValidEmail(emailuser)){
    //     sendEmail(event);
    //   }
    //   else{
    //     toast.warning("Please Enter a Valid Email and then Retry", {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       // theme:"dark",
    //       className: 'toast-custom-style',
    //     });
    //   }
    // }
    
  return (
    <>
     <Scrolltop/>
    <div className="temp"></div>
    <AnimatePresence>
    <section className="faqsection" id="mainfaqcontainer">

    <motion.div className='starter'
    initial={{opacity:0}}
    whileInView={{opacity:1}}
    exit={{opacity:0, transition:{duration:0,delay:0}}}
    transition={{delay:0,duration:2}}
    >
                      
            <h2 className="heading">Help Center </h2>   
        
         </motion.div>

        <div className="sect">

        <motion.div 
          initial={{x:"-100%",opacity:0}}
          whileInView={{x:0,opacity:1}}
          exit={{x:"-100%",opacity:0, transition:{duration:0,delay:0}}}
          transition={{duration:1,delay:1,ease:'easeOut'}}>

            <div className="faq_text sectdiv" >
               <h1 className="secondarycolor">FAQs</h1>
               <p>How can we help you?</p>
               <h2>Support</h2>
               <Lottie animationData={faqdata} key={2}></Lottie>
             </div>
        </motion.div>

        <motion.div 
             initial={{x:"100%",opacity:0}}
             whileInView={{x:0,opacity:1}}
             exit={{x:"100%",opacity:0, transition:{duration:0,delay:0}}}
              transition={{duration:1,delay:1,ease:'easeOut'}}  >

          <div className="faq_list sectdiv"> 
        
          {data.map((item,i) => (
          <div className="item">
          <div className="title_faq" onClick={() => toggle(i)}>
            <h3>{item.questionn}</h3>
            <span>
              {selected === i ? 
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-up" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z"/>
                </svg> : 
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-down" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>}
            </span>
          </div>
          <div 
          className={ selected === i ? 'content show' : 'content'
          }>{item.answer}</div>

        </div>
      ))}
          </div>
      </motion.div>
        </div>

     


        <motion.div className='contact'
    initial={{opacity:0}}
    whileInView={{opacity:1}}
    exit={{opacity:0, transition:{duration:0,delay:0}}}
    transition={{delay:0,duration:2}}

    >
            <p>Don't have your questions answered?</p>
            <h1 className="contactus secondarycolor">Contact Us</h1>

            <div className="contact_form ">
               <form className="grid_container" ref={form} onSubmit={sendEmail}>
               <div className="contact_flexo">
                  {/* <label className="item1 form_text">
                     Name:
                  </label> */}
                  <input className="item2 inputform" type="text" name="name" placeholder="Name"
                  autoFocus
                  value={nameuser}
                  onChange={namechange}/>
                </div>
                <div className="contact_flexo">
                  {/* <label className="item3 form_text">
                     Email:
                  </label> */}
                  <input className="item4 inputform" type="email" name="email" required="true" 
                    autoFocus
                    value={emailuser}
                    onChange={onChange} placeholder="Email Address" />
                </div>
                <div className="contact_flexo">
                  {/* <label className="item5 form_text">
                     Question:
                  </label> */}
                  <input className="item6 inputform" type="text" name="message" required="true" placeholder="Your Question"  autoFocus
                  value={questionuser}
                  onChange={questionchange}/>
                </div>
                <div className="submitform">
                  <input className="item7 inout" type="submit" value="Submit" required="true" />
                  <ToastContainer/>
                </div>
               </form> 
            </div>

        
        </motion.div>   
    </section>
    </AnimatePresence>


  </>
  );
}

const data = [
  {
    questionn: 'Why is high-quality data annotation crucial for AI models?',
    answer: 'High-quality data annotation ensures that AI models receive accurate and precise labeled data, enabling them to learn the right patterns and make accurate predictions. It enhances model performance, reduces biases, and improves the overall reliability of AI systems.'
  },
  {
    questionn: 'What are the challenges in data annotation and how can they be addressed?',
    answer: 'Data annotation can pose challenges such as complex tasks, large datasets, managing timelines, and maintaining quality control. These challenges can be addressed by providing clear guidelines, training annotators, implementing quality control mechanisms, and fostering iterative feedback loops.'
  },
  {
    questionn: 'What is the impact of inconsistent annotations on AI model performance?',
    answer: "Inconsistent annotations can introduce biases and lead to incorrect model behavior. AI models rely on consistent and accurate annotations for training and making predictions. Inconsistencies can hinder the model's ability to generalize and perform well on unseen data."
  },
  {
    questionn: 'How does high-quality data annotation contribute to the scalability of AI models?',
    answer: 'High-quality annotations ensure consistent and standardized labeled data, making it easier to scale AI models across different datasets and use cases. Consistency in annotations enables the transferability of models to real-world scenarios and enhances their scalability.'
  },
  {
    questionn: 'What are some best practices for achieving high-quality annotations?',
    answer: ' Best practices include providing clear annotation guidelines, training annotators, implementing quality control measures, and fostering iterative feedback loops. These practices help maintain consistency, accuracy, and adherence to labeling criteria.'
  },
  {
    questionn: 'How can fine-grained annotations enhance AI model performance?',
    answer: 'Fine-grained annotations provide detailed and granular information to AI models, allowing them to capture intricate patterns and make more accurate predictions. Fine-grained annotations are especially valuable in tasks that require precise object detection, segmentation, or sentiment analysis.'
  },
  {
    questionn: 'What is the role of data privacy and security in data annotation for AI models',
    answer: 'Data privacy and security are essential considerations in data annotation. Protecting sensitive data, ensuring compliance with privacy regulations, and implementing secure storage and access controls are crucial to maintaining data integrity and protecting user information.'
  },
];


export default Faq;


