import React from "react";
import "./Industry.css";
import { motion } from "framer-motion";
import Scrolltop from "../../Components/Scrolltop";
import { useNavigate } from "react-router-dom";
import argi from "../../Assets/Industry/poater_agri.jpg";
import healthcare from "../../Assets/Industry/poster_healtcare.jpg";
import automotive from "../../Assets/Industry/poster_auto.jpg";
import industry from "../../Assets/Industry/poster_ind.jpg";
import energy from "../../Assets/Industry/poster-energy.jpg";
import bank from "../../Assets/Industry/poster-bank.jpg";
import retail from "../../Assets/Industry/poster-retail.jpg";
import fitness from "../../Assets/Industry/3804819.jpg";
import Education from "../../Assets/Industry/Education.jpg";
import media from "../../Assets/Industry/media.jpg";
import Realestate from "../../Assets/Industry/real_estate.jpg";
import travel from "../../Assets/Industry/travel.jpg";
const Industry = () => {
  const navigate = useNavigate();
  const handledirect = (page) => {
    navigate(`${page}`);
  };
  return (
    <>
      <Scrolltop />
      <div className="temp"></div>
      <div className="start_ind">
        <h1>Explore All Industries</h1>
        <p className="light">Automate any visual task with LabelX AI workflows. Label data, collaborate in real-time, and build AI models in hours, not weeks.</p>
        <div className="btn_demo">
        <button className="demo" onClick={()=>navigate("/contactus")}>Contact us</button>
        </div>
      </div>




      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0, delay: 0 } }}
        transition={{ delay: 0.5, duration: 2 }}
      >
        <div className="whole_ind">
          <div className="industry_container">
            <div className="industry_card fiill">
              <img
                src={argi}
                alt="Card"
                style={{ height: "750", width: "1260" }}
              />
              <div className="industry_card-body" onClick={() => handledirect("agriculture")}>
                <div className="industry_card-text">
                  <h3>Agriculture</h3>
                  <p>
                    Crop images for disease detection and yield prediction.
                    Weather and soil data for precision agriculture and crop
                    management.
                  </p>
                </div>
                {/* <button onClick={() => handledirect("agriculture")}>
                  More...
                </button> */}
              </div>
            </div>
            <div className="industry_card" onClick={() => handledirect("health")}>
              {/* <div className="circle_image"> */}
              <img src={healthcare} alt="Card" />
              {/* </div> */}
              <div className="industry_card-body">
                <div className="industry_card-text">
                  <h3>Healthcare</h3>
                  <p>
                    Medical imaging data for diagnostics, disease detection,
                    and treatment planning.
                  </p>
                </div>
                {/* <button onClick={() => handledirect("health")}>More...</button> */}
              </div>
            </div>
            <div className="industry_card" onClick={() => handledirect("automotive")}>
              <img src={automotive} alt="Card" />
              <div className="industry_card-body">
                <div className="industry_card-text">
                  <h3>Automotive</h3>
                  <p>
                    Road and traffic data for autonomous vehicle development
                    and traffic management systems
                  </p>
                </div>
                {/* <button onClick={() => handledirect("automotive")}>
                  More...
                </button> */}
              </div>
            </div>
            <div className="industry_card contt" >
              <img src={industry} alt="Card" />
              <div className="industry_card-body"onClick={() => handledirect("manufacturer")}>
                <div className="industry_card-text">
                  <h3>Industry</h3>
                  <p>
                    Sensor data for equipment monitoring and predictive
                    maintenance.
                  </p>
                </div>
                {/* <button onClick={() => handledirect("manufacturer")}>
                  More...
                </button> */}
              </div>
            </div>

            <div className="industry_card">
              <img src={energy} alt="Card" />
              <div className="industry_card-body" onClick={() => handledirect("energy")}>
                <div className="industry_card-text">
                  <h3>Energy</h3>
                  <p>
                    Sensor data from energy grids for predictive maintenance
                    and fault detection.
                  </p>
                </div>
                {/* <button onClick={() => handledirect("energy")}>More...</button> */}
              </div>
            </div>
            <div className="industry_card contt">
              <img src={bank} alt="Card" />
              <div className="industry_card-body" onClick={() => handledirect("finance")}>
                <div className="industry_card-text">
                  <h3>Finance</h3>
                  <p>
                    Financial transaction data for fraud detection and risk
                    analysis.
                  </p>
                </div>
                {/* <button onClick={() => handledirect("finance")}>More...</button> */}
              </div>
            </div>

            <div className="industry_card fiill">
              <img src={retail} alt="Card" />
              <div className="industry_card-body" onClick={() => handledirect("ecommerce")}>
                <div className="industry_card-text">
                  <h3>Retail</h3>
                  <p>
                    Product images and descriptions for visual search,
                    recommendation systems, and inventory management.
                  </p>
                </div>
                {/* <button onClick={() => handledirect("ecommerce")}>
                  More...
                </button> */}
              </div>
            </div>

            <div className="industry_card fiill">
              <img src={fitness} alt="Card" />
              <div className="industry_card-body" onClick={() => handledirect("sports")}>
                <div className="industry_card-text">
                  <h3>Sports </h3>
                  <p>
                    Sports event data for match analysis and player
                    performance evaluation.
                  </p>
                </div>

                {/* <button onClick={() => handledirect("sports")}>More...</button> */}
              </div>
            </div>

            <div className="industry_card fiill">
              <img src={media} alt="Card" />
              <div className="industry_card-body" onClick={() => handledirect("media")}>
                <div className="industry_card-text">
                  <h3>Media</h3>
                  <p>
                    Video streaming data for content recommendation and user
                    behavior analysis.
                  </p>
                </div>
                {/* <button onClick={() => handledirect("media")}>More...</button> */}
              </div>
            </div>

            {/* <div className="industry_card fiill">
              <img src={travel} alt="Card" />
              <div className="industry_card-body">
                <div className="industry_card-text">
                  <h3>Travel & Tourism</h3>
                  <p>
                    Annotating tourist location images for tourism AI.
                    <br />Gathering traveler reviews for sentiment analysis.
                  </p>
                </div>
                <button onClick={() => handledirect("travel")}>More...</button>
              </div>
            </div> */}

            <div className="industry_card fiill">
              <img src={Education} alt="Card" />
              <div className="industry_card-body" onClick={() => handledirect("education")}>
                <div className="industry_card-text">
                  <h3>Education</h3>
                  <p>
                    Annotating educational content for interactive learning.
                  </p>
                </div>
                {/* <button onClick={() => handledirect("education")}>
                  More...
                </button> */}
              </div>
            </div>

            {/* <div className="industry_card fiill">
              <img src={Realestate} alt="Card" />
              <div className="industry_card-body">
                <div className="industry_card-text">
                  <h3>RealEstate</h3>
                  <p>
                    Annotating property images for real estate analysis.
                    <br />Transcribing property listings for easy search.
                  </p>
                </div>

                <button onClick={() => handledirect("realestate")}>
                  More...
                </button>
              </div>
            </div> */}
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Industry;
