import React from "react";
import "./Auto.css";
// import x from "../../../Assets/audio_trans.jpg";
import vech_anno from "../../../Assets/Industr_img/automotive_anno.jpg"
import vech_sour from "../../../Assets/Industr_img/automotive_data.jpg"
import vech_trans from "../../../Assets/Industr_img/automotive_trans.jpg"
const  Automotive= () => {
  return (
    <>
      <div className="temp"></div>
      <div className="ecommerce-container">
        <section className="data-annotation-section alll">
          <h2 className="argri">Data Annotation</h2>
          <div className="image-gallery">{/* Place product images here */}</div>
        </section>
        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={vech_anno} alt="temp" id="veh_sour"/>
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Annotating road scenes and objects is crucial for developing robust AI systems in autonomous driving. By labeling data with information about lanes, traffic signs, pedestrians, vehicles, and other objects, machine learning algorithms can understand and navigate complex road scenarios. Accurate data annotation ensures the safety and reliability of autonomous vehicles, enabling them to make informed decisions and react appropriately to changing environments, ultimately leading to the advancement and widespread adoption of self-driving technology.
            </p>
          </div>
        </div>
        <section className="data-collection-section alll">
          <h2 className="argri">Data Collection</h2>
          <div className="image-gallery">
            {/* Place product images for recommendation algorithms */}
          </div>
        </section>

        <div className="service-section">
          <div className="service-section-image" id="argii">
            <img src={vech_sour} alt="temp" id="veh_s"/>
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Gathering sensor data is essential for vehicle simulation and testing. This involves equipping vehicles with various sensors like LiDAR, cameras, and radar to collect real-world data during test drives. Collected data is used to create virtual simulations, allowing engineers to validate and optimize autonomous driving algorithms in a controlled and safe environment. The extensive and diverse dataset helps improve system performance, address edge cases, and ensure the safety and efficiency of self-driving vehicles in different driving conditions.
            </p>
          </div>
        </div>


        <section className="transcription-section alll">
          <h2 className="argri">Transcription</h2>
          {/* Place audio reviews and transcription here */}
        </section>

        <div className="service-section reverse">
          <div className="service-section-image" id="argii">
            <img src={vech_trans} alt="temp"  />
          </div>
          <div className="service-section-content">
            <p className="service-section-description">
            Transcribing vehicle telemetry data involves converting sensor data and vehicle metrics into a usable format for analysis. Telemetry data includes information on vehicle speed, acceleration, braking, steering angles, and other sensor readings. Transcribing this data enables engineers to analyze vehicle performance, detect anomalies, and fine-tune autonomous driving algorithms. The insights gained from telemetry data transcription play a crucial role in enhancing the overall driving experience, optimizing fuel efficiency, and ensuring the smooth integration of autonomous features into modern vehicles.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Automotive;
