import React, { useEffect, useRef } from 'react'
import './team.css'
import VanillaTilt from "vanilla-tilt"
import knowCommittee from "../data"
import {AnimatePresence, motion} from 'framer-motion'

function Tilt(props) {
  const { options, ...rest } = props;
  const tilt = useRef(null);

  useEffect(() => {
    VanillaTilt.init(tilt.current, options);
  }, [options]);

  return <div ref={tilt} {...rest} />;
}

function Teamcard(){

  // const options = {
  //   scale: 1.2,
  //   speed: 1000,
  //   max: 30
  // };

  return (
    <>
      <AnimatePresence>
      <motion.div className='mottoContainer'
      initial={{opacity:0}}
      animate={{opacity:1}}
      exit={{opacity:0, transition:{duration:0,delay:0}}}
      transition={{delay:0,duration:2}}
      >

      <div className = "mottoText">
        Know More About<br></br>
        <h1>Our Team</h1> 
        </div>
      </motion.div>
      <div className = "committeeContainer">

        {/*<h1 className='commiteeTitle'>Know Our Commitee</h1> */}

        <motion.div className = "boxContainer"
        initial={{opacity:0}}
        whileInView={{opacity:1}}
        exit={{opacity:0, transition:{duration:0,delay:0}}}
        transition={{delay:0.5,duration:2}}
        >
          {
            knowCommittee && knowCommittee.map( knowCommittee => {
              return(
                // <div>
                  <div data-tilt data-tilt-glare data-tilt-max-glare="0.8">
                  <Tilt className='box' key = { knowCommittee.id }>
                    <div className = "boxTitle"> {knowCommittee.title} </div>
                    <br></br><br></br>
                    <div className='circle'></div>
                    <img className = "scomImg" src= {knowCommittee.icon} alt = "scom-img"/>
                    <br></br><br></br>
                    <div className = "boxContent">{knowCommittee.content}</div>
                  </Tilt>
                  <br></br><br></br>  
                {/* </div> */}
                </div>
              )
              }
              )
          }
        </motion.div>
      </div>
      </AnimatePresence>
    </>
  )
}
export default Teamcard;