import React,{memo} from 'react'
import {motion} from "framer-motion"
import Teamcard from "../../Components/Team_cards/Teamcard"
// import Faq from '../Faq/faq'
// import Scrolltop from '../../Components/Scrolltop'
import "./About.css"
import Form  from "../../Components/ReviewsForm/Form"
import OurAbout from '../../Components/AboutData/Abouts'
const About = () => {
  
  return (
    <>
    <div className="whole_baout">
    <div className='temp'></div>
    <div className='videoSection'>
      <motion.div 
      initial={{x:"-100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"-100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:1,delay:0.3,ease:'easeOut'}}
      >
      <video className='video' autoPlay muted loop>
          <source src={require("./_import_624e7ce9d9a039.44473909_preview.mp4")} type="video/mp4"></source>
      </video>
      </motion.div>
      <motion.div className='videoText'
      initial={{x:"100%",opacity:0}}
      whileInView={{x:0,opacity:1}}
      exit={{x:"100%",opacity:0, transition:{duration:0,delay:0}}}
      transition={{duration:1,delay:0.6,ease:'easeOut'}}
      ><h1>Here’s a glimpse of  us!</h1></motion.div>
    </div>
    <OurAbout/>
    {/* <Teamcard/> */}
    {/* <Faq/> */}
    {/* <Form/> */}
    </div>
    </>
  )
}

export default memo(About);
